import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient':
    'linear-gradient(135.05deg, #26a69a 1.74%, #004d40 100%)',
  'color-error': '#f14643',
  'color-primary-1': '#f0f9ff',
  'color-primary-dark': '#075985',
  'color-primary': '#0284c7',
  'color-secondary-1': '#f0f9ff',
  'color-secondary-dark': '#b2400e',
  'color-secondary': '#d97706',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#3a7ccd',
  'color-tertiary-dark': '#1f508e',
  'font-color-highlight': 'var(--color-primary)',
  'font-color-primary': '#3a474e',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'Roboto',
  'font-family-titles': 'Roboto',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'Roboto',
    axes: 'wght@400;700',
  },
];
