import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as BaseLink, LinkProps, To, useParams } from 'react-router-dom';

import { getPathnameWithLocale } from '../../utils/urlUtils';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, ...props }, ref) => {
    const params = useParams();

    const toWithLocale: To =
      typeof to === 'string'
        ? getPathnameWithLocale(to, params.lang)
        : {
            ...to,
            pathname:
              to.pathname !== undefined
                ? getPathnameWithLocale(to.pathname, params.lang)
                : undefined,
          };

    return <BaseLink ref={ref} to={toWithLocale} {...props} />;
  },
);

Link.displayName = 'Link';
