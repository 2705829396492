const StorefrontIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 9C21.7504 8.93027 21.7409 8.86083 21.7219 8.79375L20.3766 4.0875C20.2861 3.77523 20.0971 3.50059 19.8378 3.30459C19.5784 3.10858 19.2626 3.00174 18.9375 3H5.0625C4.73741 3.00174 4.4216 3.10858 4.16223 3.30459C3.90287 3.50059 3.71386 3.77523 3.62344 4.0875L2.27906 8.79375C2.2597 8.86079 2.24991 8.93022 2.25 9V10.5C2.25 11.0822 2.38554 11.6563 2.6459 12.1771C2.90625 12.6978 3.28427 13.1507 3.75 13.5V19.5C3.75 19.8978 3.90804 20.2794 4.18934 20.5607C4.47064 20.842 4.85218 21 5.25 21H18.75C19.1478 21 19.5294 20.842 19.8107 20.5607C20.092 20.2794 20.25 19.8978 20.25 19.5V13.5C20.7157 13.1507 21.0937 12.6978 21.3541 12.1771C21.6145 11.6563 21.75 11.0822 21.75 10.5V9ZM5.0625 4.5H18.9375L20.0081 8.25H3.99469L5.0625 4.5ZM9.75 9.75H14.25V10.5C14.25 11.0967 14.0129 11.669 13.591 12.091C13.169 12.5129 12.5967 12.75 12 12.75C11.4033 12.75 10.831 12.5129 10.409 12.091C9.98705 11.669 9.75 11.0967 9.75 10.5V9.75ZM8.25 9.75V10.5C8.25 11.0967 8.01295 11.669 7.59099 12.091C7.16903 12.5129 6.59674 12.75 6 12.75C5.40326 12.75 4.83097 12.5129 4.40901 12.091C3.98705 11.669 3.75 11.0967 3.75 10.5V9.75H8.25ZM18.75 19.5H5.25V14.175C5.4969 14.2248 5.74813 14.2499 6 14.25C6.58217 14.25 7.15634 14.1145 7.67705 13.8541C8.19776 13.5937 8.6507 13.2157 9 12.75C9.3493 13.2157 9.80224 13.5937 10.3229 13.8541C10.8437 14.1145 11.4178 14.25 12 14.25C12.5822 14.25 13.1563 14.1145 13.6771 13.8541C14.1978 13.5937 14.6507 13.2157 15 12.75C15.3493 13.2157 15.8022 13.5937 16.3229 13.8541C16.8437 14.1145 17.4178 14.25 18 14.25C18.2519 14.2499 18.5031 14.2248 18.75 14.175V19.5ZM18 12.75C17.4033 12.75 16.831 12.5129 16.409 12.091C15.9871 11.669 15.75 11.0967 15.75 10.5V9.75H20.25V10.5C20.25 11.0967 20.0129 11.669 19.591 12.091C19.169 12.5129 18.5967 12.75 18 12.75Z"
      fill="white"
    />
  </svg>
);

export default StorefrontIcon;
