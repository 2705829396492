import { Capacitor } from '@capacitor/core';
import { useConfig } from '@travelwin/core';
import { openUrl } from '@travelwin/core/src/utils/urlUtils';
import {
  AFConstants,
  AFInit,
  AppsFlyer as AppsFlyerCapacitor,
  OnDeepLink,
} from 'appsflyer-capacitor-plugin';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { useEffect } from 'react';

const onDeepLinking = (event: OnDeepLink) => {
  if (event.status === 'FOUND') {
    const deepLink = event.deepLink?.deep_link_value;
    if (deepLink) {
      openUrl(deepLink);
    }
  } else {
    if (event.error) {
      console.error(event.error);
    }
  }
};

function AppsFlyer() {
  const { APPSFLYER_DEVKEY } = useConfig();

  useEffect(() => {
    if (!Capacitor.isNativePlatform() || !APPSFLYER_DEVKEY) {
      return;
    }

    (async () => {
      // So far, we only need ATT for iOS for AppsFlyer.
      // We should move this out of here if we're using it for something else in the future.
      if (Capacitor.getPlatform() === 'ios') {
        const attStatus = await AppTrackingTransparency.getStatus();

        if (attStatus.status !== 'authorized') {
          await AppTrackingTransparency.requestPermission();
        }
      }

      const afConfig: AFInit = {
        appID: '1554947648', // iOS appID
        devKey: APPSFLYER_DEVKEY,
        waitForATTUserAuthorization: 10, // more on this here https://support.appsflyer.com/hc/en-us/articles/207032066-Basic-SDK-integration-guide#waitforattuserauthorization-overview
        deepLinkTimeout: 10000, // default is 3000ms
        registerOnDeepLink: true,
      };

      const conversionCallbackListener = AppsFlyerCapacitor.addListener(
        AFConstants.UDL_CALLBACK,
        onDeepLinking,
      );

      await AppsFlyerCapacitor.setOneLinkCustomDomain({
        domains: ['app.simlocal.com'],
      });

      await AppsFlyerCapacitor.initSDK(afConfig);

      return () => {
        conversionCallbackListener.remove();
      };
    })();
  }, []);

  return null;
}

export default AppsFlyer;
