const SimOutline = () => (
  <svg
    width="83"
    height="98"
    viewBox="0 0 83 98"
    fill="none"
    stroke="#E45F78"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.502 97H5.498a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h48.004l28 28v64a4 4 0 0 1-4 4z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="8 8"
    />
  </svg>
);

export default SimOutline;
