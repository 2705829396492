import { Capacitor, CapacitorHttp, HttpResponse } from '@capacitor/core';
import { useQuery } from '@tanstack/react-query';
import { Resource } from '@travelwin/core';

import { request } from '../helpers/requests';

const options = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const staleTime = 5 * 1000;

export type PromoBannerType = {
  promo_banner_html: string;
  retailer_id: string;
};

export function getCountries(): Promise<Resource[]> {
  return request('ecommerce/resources/countries', options);
}

export const useCountriesQuery = () => {
  return useQuery({
    staleTime,
    queryKey: ['COUNTRIES'],
    queryFn: async () => {
      return await getCountries();
    },
  });
};

export type FeaturedCountry = Resource<string> & {
  multi_destination: boolean;
};

export function getFeaturedCountries(): Promise<FeaturedCountry[]> {
  return request('ecommerce/resources/featured_countries', options);
}

export const useFeaturedCountriesQuery = () => {
  return useQuery({
    staleTime,
    queryKey: ['FEATURED_COUNTRIES'],
    queryFn: getFeaturedCountries,
  });
};

export interface OnlineShopTerritory extends Resource {
  url: string;
}

export function getCountriesDomain(): Promise<
  OnlineShopTerritory[] | undefined
> {
  return request('ecommerce/resources/shop_territories', options);
}

export const useCountriesDomainQuery = () => {
  return useQuery({
    staleTime,
    queryKey: ['COUNTRIES_DOMAIN'],
    queryFn: async () => {
      const data = await getCountriesDomain();
      // data is undefined if /shop_territories is not configured
      if (!data) return [];
      return data;
    },
  });
};

const getPromoBanner = (): Promise<PromoBannerType> => {
  return request('ecommerce/resources/promo_banner', options);
};

export const usePromoBannerQuery = () => {
  return useQuery({
    staleTime,
    queryKey: ['PROMO_BANNER'],
    queryFn: () => {
      return getPromoBanner();
    },
  });
};

export const getViewerCountryCode = async () => {
  const url = new URL(window.location.href);
  if (Capacitor.isNativePlatform()) {
    const response: HttpResponse = await CapacitorHttp.get({
      url: url.origin.replace('capacitor://', 'https://'),
    });
    return (
      response.headers['Cloudfront-Viewer-Country'] ??
      response.headers['cloudfront-viewer-country']
    );
  } else {
    const response = await fetch(url.origin);
    return response.headers.get('Cloudfront-Viewer-Country');
  }
};
