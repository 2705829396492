export default function Stripe({ title }: { title: string }) {
  return (
    <svg
      width="44"
      height="18"
      viewBox="0 0 44 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M30.3107 3.70714C29.8877 3.7047 29.4684 3.78578 29.0767 3.94573C28.6851 4.10569 28.3289 4.34138 28.0286 4.63928L27.8786 3.9H25.3179V17.7429L28.2322 17.1107V13.7571C28.8118 14.2426 29.544 14.5081 30.3 14.5071C32.3786 14.5071 34.2857 12.8036 34.2857 9.03214C34.2857 5.58214 32.3572 3.70714 30.3107 3.70714ZM29.6143 11.8821C29.3587 11.8969 29.103 11.8545 28.8658 11.7582C28.6286 11.6618 28.4158 11.5138 28.2429 11.325V6.92143C28.4165 6.72569 28.6317 6.57121 28.8727 6.46926C29.1136 6.36731 29.3743 6.32046 29.6357 6.33214C30.7072 6.33214 31.4357 7.54285 31.4357 9.09643C31.4357 10.65 30.6964 11.8821 29.6143 11.8821Z"
        fill="white"
      />
      <path
        d="M39.6643 3.70714C36.8572 3.70714 35.1536 6.12857 35.1536 9.18214C35.1536 12.7929 37.1679 14.625 40.0393 14.625C41.16 14.6433 42.2666 14.374 43.2536 13.8429V11.4321C42.3573 11.8844 41.3646 12.1123 40.3607 12.0964C39.2036 12.0964 38.2179 11.6786 38.0679 10.275H43.8322C43.8322 10.1143 43.8322 9.48214 43.8322 9.20357C43.9286 6.12857 42.4715 3.70714 39.6643 3.70714ZM38.0893 8.06785C38.0893 6.71785 38.9143 6.15 39.6429 6.15C40.3715 6.15 41.1429 6.71785 41.1429 8.06785H38.0893Z"
        fill="white"
      />
      <path
        d="M17.4536 4.70357L17.2715 3.825H14.7857V14.2286H17.6572V7.17857C17.9165 6.86372 18.2657 6.63553 18.6582 6.52448C19.0507 6.41343 19.4678 6.42483 19.8536 6.55714V3.825C19.4153 3.65328 18.9292 3.64783 18.4872 3.80966C18.0451 3.97149 17.6775 4.28944 17.4536 4.70357Z"
        fill="white"
      />
      <path
        d="M2.94643 6.95357C2.94643 6.48214 3.33214 6.3 3.94286 6.3C4.955 6.32179 5.94711 6.58635 6.83571 7.07143V4.25357C5.917 3.8821 4.93377 3.69638 3.94286 3.70714C1.575 3.70714 0 4.97143 0 7.09286C0 10.4143 4.43571 9.87857 4.43571 11.3036C4.43571 11.8607 3.96429 12.0429 3.36429 12.0429C2.23228 11.9767 1.13148 11.6465 0.149999 11.0786V13.9607C1.16157 14.4119 2.25666 14.6455 3.36429 14.6464C5.78571 14.6464 7.45714 13.4143 7.45714 11.2607C7.40357 7.66071 2.94643 8.30357 2.94643 6.95357Z"
        fill="white"
      />
      <path
        d="M11.325 1.36071L8.48572 1.98214V11.55C8.47108 11.9532 8.53882 12.3552 8.68479 12.7313C8.83076 13.1075 9.05189 13.4499 9.33468 13.7377C9.61746 14.0255 9.95597 14.2526 10.3295 14.4052C10.703 14.5578 11.1037 14.6325 11.5071 14.625C12.211 14.6565 12.9124 14.5206 13.5536 14.2286V11.8179C13.1786 11.9786 11.3464 12.525 11.3464 10.7464V6.46071H13.5536V3.95357H11.3464L11.325 1.36071Z"
        fill="white"
      />
      <path
        d="M20.9357 2.925L23.8607 2.31429V0L20.9357 0.610714V2.925Z"
        fill="white"
      />
      <path d="M23.8607 3.9H20.9357V14.2286H23.8607V3.9Z" fill="white" />
    </svg>
  );
}
