const CheckIcon = ({
  className,
  color = 'var(--color-primary)',
}: {
  className: string;
  color: string;
}) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54985 18.5L3.84985 12.8L5.27485 11.375L9.54985 15.65L18.7249 6.47501L20.1499 7.90001L9.54985 18.5Z"
      fill={color}
    />
  </svg>
);

export default CheckIcon;
