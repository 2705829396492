export default function GooglePay({ title }: { title: string }) {
  return (
    <svg
      width="43"
      height="18"
      viewBox="0 0 43 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7264 13.9471V8.90213H22.3736C23.4584 8.90213 24.3739 8.54461 25.1203 7.83951L25.2995 7.66075C26.6629 6.20088 26.5733 3.92666 25.1203 2.57603C24.3939 1.86099 23.3987 1.47368 22.3736 1.49354H18.1241V13.9471H19.7264ZM19.7266 7.37272V3.0229H22.414C22.9913 3.0229 23.5388 3.24138 23.9469 3.63863C24.8128 4.47284 24.8327 5.86319 23.9966 6.7272C23.5885 7.15423 23.0112 7.39258 22.414 7.37272H19.7266Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7734 6.09164C32.0867 5.46598 31.1513 5.14819 29.967 5.14819C28.4443 5.14819 27.2999 5.70433 26.5435 6.80668L27.9567 7.69055C28.4742 6.93579 29.1808 6.5584 30.0765 6.5584C30.6437 6.5584 31.1911 6.76696 31.619 7.14434C32.037 7.50186 32.2758 8.01827 32.2758 8.56448V8.93194C31.6588 8.59428 30.8826 8.41552 29.9272 8.41552C28.8126 8.41552 27.9169 8.67373 27.2501 9.20007C26.5833 9.72642 26.245 10.4216 26.245 11.3055C26.225 12.1099 26.5734 12.8746 27.1904 13.391C27.8174 13.9471 28.6135 14.2252 29.549 14.2252C30.6537 14.2252 31.5294 13.7386 32.1962 12.7653H32.2659V13.9471H33.7985V8.69359C33.7985 7.59124 33.4601 6.7173 32.7734 6.09164ZM28.4247 12.378C28.0963 12.1397 27.8972 11.7524 27.8972 11.3352C27.8972 10.8685 28.1162 10.4812 28.5442 10.1733C28.9821 9.86545 29.5296 9.70655 30.1765 9.70655C31.0724 9.69662 31.7691 9.89524 32.2668 10.2925C32.2668 10.9678 31.998 11.5537 31.4705 12.0503C30.9927 12.527 30.3458 12.7951 29.6689 12.7951C29.221 12.8051 28.783 12.6561 28.4247 12.378Z"
        fill="white"
      />
      <path
        d="M37.2419 17.6912L42.596 5.42626H40.8544L38.3764 11.5438H38.3466L35.8088 5.42626H34.0672L37.5803 13.4009L35.5899 17.6912H37.2419Z"
        fill="white"
      />
      <path
        d="M14.1948 7.80971C14.1948 7.32308 14.155 6.83646 14.0754 6.35977H7.31798V9.11068H11.1893C11.0301 9.99455 10.5126 10.789 9.75621 11.2856V13.0732H12.0651C13.4185 11.8318 14.1948 9.99455 14.1948 7.80971Z"
        fill="white"
      />
      <path
        d="M7.31827 14.8012C9.24895 14.8012 10.8808 14.1656 12.0651 13.0732L9.75621 11.2856C9.10933 11.7226 8.28361 11.9708 7.31827 11.9708C5.4473 11.9708 3.86494 10.7096 3.29768 9.0213H0.919166V10.8685C2.1333 13.2817 4.61134 14.8012 7.31827 14.8012Z"
        fill="white"
      />
      <path
        d="M3.29768 9.0213C2.99908 8.13744 2.99935 7.17415 3.29795 6.28035V4.4431H0.919057C-0.106157 6.45911 -0.106157 8.84257 0.919057 10.8586L3.29768 9.0213Z"
        fill="white"
      />
      <path
        d="M7.31827 3.33079C8.34332 3.31093 9.32856 3.69824 10.065 4.40335L12.1151 2.35755C10.8114 1.14595 9.09967 0.48057 7.31827 0.500432C4.61134 0.500432 2.13319 2.02984 0.919057 4.4431L3.29768 6.29026C3.86494 4.59204 5.4473 3.33079 7.31827 3.33079Z"
        fill="white"
      />
    </svg>
  );
}
