import { Capacitor, Plugins } from '@capacitor/core';
const { FacebookSDKPlugin } = Plugins;

const isEnabled = Capacitor.isNativePlatform();

class MetaAnalytics {
  sendViewedContentEvent = async ({
    amount,
    currency,
    contentID,
    contentType,
  }: {
    amount: number;
    currency: string;
    contentID: string;
    contentType: string;
  }) => {
    if (isEnabled) {
      await FacebookSDKPlugin.logViewedContent({
        amount,
        currency,
        contentID,
        contentType,
      });
    }
  };

  sendAddedToCartEvent = async ({
    amount,
    currency,
    contentID,
    contentType,
  }: {
    amount: number;
    currency: string;
    contentID: string;
    contentType: string;
  }) => {
    if (isEnabled) {
      await FacebookSDKPlugin.logAddedToCart({
        amount,
        currency,
        contentID,
        contentType,
      });
    }
  };

  sendPurchaseEvent = async ({
    amount,
    currency,
    contentID,
    contentType,
  }: {
    amount: number;
    currency: string;
    contentID: string;
    contentType: string;
  }) => {
    if (isEnabled) {
      await FacebookSDKPlugin.logPurchase({
        amount,
        currency,
        contentID,
        contentType,
      });
    }
  };

  sendSignUpEvent = async ({ method }: { method: string }) => {
    if (isEnabled) {
      await FacebookSDKPlugin.logSignUp({
        registrationMethod: method,
      });
    }
  };
}

const MetaMobileAnalytics = new MetaAnalytics();

export default MetaMobileAnalytics;
