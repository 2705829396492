const TikTok = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
  >
    <g clipPath="url(#clip0_62_13546)">
      <g clipPath="url(#clip1_62_13546)">
        <path
          fill="#fff"
          d="M19.558 6.846a4.16 4.16 0 0 1-4.154-4.154A.69.69 0 0 0 14.71 2H11.25a.69.69 0 0 0-.692.692v11.423a1.73 1.73 0 1 1-2.473-1.564.69.69 0 0 0 .396-.626V8.231a.692.692 0 0 0-.814-.682c-3.088.55-5.417 3.373-5.417 6.566a6.577 6.577 0 0 0 13.154 0V10.68a8.94 8.94 0 0 0 4.154 1.013.69.69 0 0 0 .692-.692V7.538a.69.69 0 0 0-.692-.692m-.693 3.43a7.55 7.55 0 0 1-3.75-1.397.692.692 0 0 0-1.096.563v4.673a5.192 5.192 0 1 1-10.384 0c0-2.241 1.44-4.251 3.461-4.984v2.394a3.116 3.116 0 1 0 4.846 2.59V3.385h2.12a5.55 5.55 0 0 0 4.803 4.803z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_62_13546">
        <path fill="#fff" d="M0 .5h24v24H0z"></path>
      </clipPath>
      <clipPath id="clip1_62_13546">
        <path fill="#fff" d="M0 .5h24v24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default TikTok;
