import './Icon.scss';

import CardPayment from './CardPayment';
import CompleteIcon from './CompleteIcon';
import Esim from './Esim';
import GoogleRating from './GoogleRating';
import { ApplePay, GooglePay, PayPal, Stripe } from './Payments';
import ProcessingIcon from './ProcessingIcon';
import SimOutline from './SimOutline';
import { Facebook, Instagram, LinkedIn, TikTok, X, Youtube } from './SNS';
import StorefrontIcon from './StorefrontIcon';
import TripadvisorRatingIcon from './TripadvisorRating';
import CheckIcon from './CheckIcon';

export type IconName = keyof typeof nameToIconMap;

export type IconColor = 'charcoal' | 'none' | 'color-neutral-7';

export const COLOR_MAPPINGS: { [K in IconColor]: string } = {
  charcoal: '#333333',
  none: 'none',
  'color-neutral-7': 'var(--color-neutral-7)',
};

type Props = {
  name: IconName;
  color?: IconColor;
} & Record<string, any>;

export const nameToIconMap = {
  applePay: ApplePay,
  cardPayment: CardPayment,
  completeIcon: CompleteIcon,
  eSim: Esim,
  googlePay: GooglePay,
  payPal: PayPal,
  processingIcon: ProcessingIcon,
  simOutline: SimOutline,
  stripe: Stripe,
  googleRatingIcon: GoogleRating,
  tripadvisorRatingIcon: TripadvisorRatingIcon,
  instagram: Instagram,
  facebook: Facebook,
  x: X,
  linkedIn: LinkedIn,
  youtube: Youtube,
  tikTok: TikTok,
  storeFront: StorefrontIcon,
  check: CheckIcon,
};

/**
 * Used for custom icons. Make sure an icon is not available to be imported
 * from @phosphor-icons/react package before adding an icon. A searchable list
 * of all the available icons can be found here: https://phosphoricons.com/
 */
const Icon = (props: Props) => {
  const { name, color, ...iconProps } = props;
  const IconComponent = nameToIconMap[name];
  const iconFill = color && COLOR_MAPPINGS[color];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <IconComponent {...(iconProps as any)} color={iconFill} />;
};

export default Icon;
