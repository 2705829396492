import './FreeTrialBanner.scss';

import { useConfig } from '@travelwin/core';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '../../analytics';
import { Link } from '../../components/Link';
import { useUser } from '../../context/User';
import useOrdersQuery from '../../hooks/useOrdersQuery';

const className = 'c-FreeTrialBanner';

type Props = {
  variant: 'navbar' | 'landing';
};

export const FreeTrialBanner: FC<Props> = ({ variant = 'landing' }) => {
  const { t } = useTranslation();
  const { FREE_TRIAL_OFFER_ENABLED } = useConfig();
  const { isAuthenticated } = useUser();
  const analyticsContext = useAnalytics();

  const handleClick = () => {
    analyticsContext?.trackFreeTrial({
      trial_status: 'banner-clicked',
    });
  };

  const { data: orders, isLoading: areOrdersLoading } =
    useOrdersQuery(isAuthenticated);

  // Shows up only for guests or logged in users without any plans
  if (
    !FREE_TRIAL_OFFER_ENABLED ||
    (isAuthenticated && (areOrdersLoading || orders?.length))
  ) {
    return null;
  }

  return (
    <div className={className}>
      <Link
        to={'/free-trial'}
        className={classNames(`${className}__link`, {
          [`${className}__navbar`]: variant === 'navbar',
        })}
        onClick={handleClick}
      >
        {t('landing.free_trial', 'Free Trial')}
      </Link>
    </div>
  );
};
