import {
  Auth,
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { captureException } from '@sentry/capacitor';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useConfig, useGA4 } from '@travelwin/core';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  ANALYTICS_EVENT_TYPES,
  LoginEventData,
  useAnalytics,
} from '../../analytics';
import { useNavigate } from '../../components/Link';
import { getCurrentLocale } from '../../i18n';
import { affiliateOrderService } from '../../services/order/OrderService';
import { changeMarketingConsent } from '../../services/users/UsersService';

export type UserContextType = {
  user: User | undefined;
  isAuthenticated: boolean;
  isUserLoaded: boolean;
  signIn: (email: string, password: string) => Promise<User>;
  federatedSignIn: (
    provider: CognitoHostedUIIdentityProvider,
    afterAuthPath?: string,
  ) => void;
  registerUser: (
    email: string,
    password: string,
    privacyAccepted: boolean,
    promoOptIn: boolean,
  ) => Promise<unknown>;
  resendRegistrationCode: (email: string) => Promise<void>;
  confirmRegistrationCode: (
    email: string,
    code: string,
    password?: string,
  ) => Promise<unknown>;
  updateUserPassword: (
    old_password: string,
    new_password: string,
  ) => Promise<'SUCCESS'>;
  forgotPassword: (username: string) => unknown;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    new_password: string,
  ) => Promise<string>;
  signOut: () => Promise<unknown>;
  changeMarketingConsentMutation: UseMutationResult<unknown, unknown, boolean>;
};

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined,
);

export interface User {
  email: string;
  marketingConsent?: boolean;
}

const CUSTOM_ATTRIBUTES = {
  promoOptIn: 'custom:promo_optin',
  privacyAccepted: 'custom:privacy_accepted',
};

const getUser = async ({
  bypassCache,
}: {
  bypassCache: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any | undefined> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });

    return cognitoUser;
  } catch {
    return;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapCognitoUserToUser = (cognitoUser: any): User => {
  const { email } = cognitoUser.attributes;
  const promoOptIn: string | undefined =
    cognitoUser.attributes[CUSTOM_ATTRIBUTES.promoOptIn];

  return {
    email,
    marketingConsent:
      promoOptIn === undefined ? undefined : promoOptIn === 'true',
  };
};

const registerUser = (
  email: string,
  password: string,
  privacyAccepted: boolean,
  promoOptIn: boolean,
) => {
  return Auth.signUp({
    username: email.toLowerCase(),
    password,
    attributes: {
      email: email.toLowerCase(),
      locale: getCurrentLocale(),
      /**
       * We store user last activity date in `prefferred_username` due to two reasons:
       * - it's predefined in cognito, hence no migration needed for new custom field
       * - predefined fields are searchable
       */
      preferred_username: new Date().toISOString(),
      [CUSTOM_ATTRIBUTES.privacyAccepted]: privacyAccepted.toString(),
      [CUSTOM_ATTRIBUTES.promoOptIn]: promoOptIn.toString(),
    },
  });
};

const resendRegistrationCode = (email: string) =>
  Auth.resendSignUp(email.toLowerCase());

const forgotPassword = (username: string) =>
  Auth.forgotPassword(username, {
    locale: getCurrentLocale(),
  });

const forgotPasswordSubmit = (
  username: string,
  code: string,
  new_password: string,
) => Auth.forgotPasswordSubmit(username, code, new_password);

const federatedSignIn = (
  provider: CognitoHostedUIIdentityProvider,
  afterAuthPath?: string,
) => {
  Auth.federatedSignIn({
    provider,
    customState: afterAuthPath || window.location.pathname,
  });
};

export type UserProviderProps = {
  children: ReactNode;
  initCognitoUser?: CognitoUser;
};

export const UserProvider = ({
  children,
  initCognitoUser,
}: UserProviderProps) => {
  const [userData, setUserData] = useState<
    | {
        cognitoUser: CognitoUser;
        user: User;
      }
    | undefined
  >(() => {
    if (initCognitoUser) {
      return {
        cognitoUser: initCognitoUser,
        user: mapCognitoUserToUser(initCognitoUser),
      };
    }
  });
  const [redirectPath, setRedirectPath] = useState<string>();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const cognitoUser = userData?.cognitoUser;
  const user = userData?.user;

  const { trackEvent } = useGA4();
  const { MARKETING_ENABLED } = useConfig();
  const navigate = useNavigate();
  const analyticsContext = useAnalytics();
  const queryClient = useQueryClient();

  const signIn = useCallback(async (email: string, password: string) => {
    const cognitoUser = await Auth.signIn(email.toLowerCase(), password);
    const user = mapCognitoUserToUser(cognitoUser);
    setUserData({ cognitoUser, user });
    setIsUserLoaded(true);
    // waiting for affiliate to make sure orders are lined when user enters /orders directly
    await affiliateOrderService();
    return user;
  }, []);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
      queryClient.clear();
      trackEvent(ANALYTICS_EVENT_TYPES.Logout);
    } catch (error) {
      captureException(error);
    }
  }, [queryClient, trackEvent]);

  const refreshUser = async ({ bypassCache = false } = {}) => {
    const cognitoUser = await getUser({ bypassCache });
    setIsUserLoaded(true);
    if (cognitoUser) {
      setUserData({ cognitoUser, user: mapCognitoUserToUser(cognitoUser) });
    }
  };

  const changeMarketingConsentMutation = useMutation({
    mutationFn: async (value: boolean) => {
      await changeMarketingConsent(value);
      return refreshUser({ bypassCache: true });
    },
  });

  const confirmRegistrationCode = async (
    email: string,
    code: string,
    password?: string,
  ) => {
    await Auth.confirmSignUp(email.toLowerCase(), code);

    if (password) {
      return signIn(email.toLowerCase(), password);
    }
  };

  const updateUserPassword = (oldPassword: string, newPassword: string) => {
    return Auth.changePassword(cognitoUser, oldPassword, newPassword);
  };

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          {
            const trackingData: LoginEventData = {
              method: 'ExternalProvider',
            };

            analyticsContext?.trackLogin(trackingData);

            setRedirectPath(data);
          }
          break;
        case 'signOut':
          setUserData(undefined);
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // redirect user to previous route after federated sign in
  useEffect(() => {
    if (user && redirectPath) {
      if (MARKETING_ENABLED && user.marketingConsent === undefined) {
        navigate('/marketing-consent', {
          replace: true,
          state: { redirectPath },
        });
      } else {
        navigate(redirectPath, { replace: true });
      }

      setRedirectPath(undefined);
    }
  }, [navigate, user, redirectPath, MARKETING_ENABLED]);

  const value = {
    user,
    isAuthenticated: !!user,
    isUserLoaded,
    signIn,
    federatedSignIn,
    registerUser,
    resendRegistrationCode,
    confirmRegistrationCode,
    updateUserPassword,
    forgotPassword,
    forgotPasswordSubmit,
    signOut,
    changeMarketingConsentMutation,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
