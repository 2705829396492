import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient': 'linear-gradient(75.96deg, #fe402a 0%, #a101ff 100%)',
  'color-error': '#f14643',
  'color-primary-1': '#f6e3e3',
  'color-primary-dark': '#ab1821',
  'color-primary': '#de0c20',
  'color-secondary-1': '#f4f5fa',
  'color-secondary-dark': '#b55768',
  'color-secondary': '#212656',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#de0c20',
  'color-tertiary-dark': '#ab1821',
  'font-color-highlight': 'transparent',
  'font-background-highlight': 'var(--background-gradient)',
  'font-color-primary': '#3a474e',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'Poppins, sans-serif',
  'font-family-titles': 'var(--font-family-body)',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'Poppins',
    axes: 'wght@400;700',
  },
];
