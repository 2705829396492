export default function PayPal({ title }: { title: string }) {
  return (
    <svg
      width="38"
      height="11"
      viewBox="0 0 38 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53438 0.00104447H1.59533C1.3942 0.00104447 1.22315 0.147201 1.19179 0.345657L0.00308314 7.8814C-0.0205434 8.03015 0.094627 8.16417 0.245475 8.16417H1.64861C1.84973 8.16417 2.02078 8.01802 2.05215 7.81916L2.37275 5.78668C2.40368 5.58763 2.57515 5.44167 2.77586 5.44167H3.70626C5.64232 5.44167 6.75961 4.50487 7.05156 2.64858C7.18302 1.83646 7.05713 1.19834 6.67668 0.751317C6.25904 0.260746 5.51802 0.00104447 4.53438 0.00104447ZM4.87346 2.75337C4.71277 3.80789 3.90697 3.80789 3.12783 3.80789H2.68431L2.99546 1.83845C3.01393 1.71953 3.11707 1.63184 3.23741 1.63184H3.44068C3.97142 1.63184 4.47213 1.63184 4.73087 1.9343C4.885 2.11485 4.93233 2.38291 4.87346 2.75337ZM13.3199 2.71957H11.9125C11.7926 2.71957 11.689 2.80726 11.6705 2.92638L11.6082 3.31991L11.5098 3.17733C11.2051 2.73508 10.5257 2.58733 9.84756 2.58733C8.29215 2.58733 6.96385 3.76514 6.70512 5.41741C6.57068 6.24146 6.7618 7.02952 7.22935 7.57915C7.65833 8.08443 8.27206 8.29502 9.00214 8.29502C10.2553 8.29502 10.9503 7.48926 10.9503 7.48926L10.8875 7.88041C10.8638 8.02995 10.979 8.16397 11.1289 8.16397H12.3968C12.5984 8.16397 12.7685 8.01782 12.8003 7.81896L13.561 3.00234C13.585 2.85419 13.4703 2.71957 13.3199 2.71957ZM11.358 5.45857C11.2222 6.26254 10.584 6.80223 9.7702 6.80223C9.36151 6.80223 9.03475 6.67118 8.82514 6.42282C8.61711 6.17624 8.53796 5.82506 8.60419 5.43412C8.73107 4.63691 9.38 4.07972 10.1815 4.07972C10.581 4.07972 10.906 4.21236 11.12 4.46291C11.3344 4.71605 11.4195 5.06922 11.358 5.45857ZM19.4014 2.71937H20.8158C21.0139 2.71937 21.1294 2.94149 21.0169 3.10395L16.3128 9.8932C16.2367 10.0032 16.1112 10.0686 15.9771 10.0686H14.5645C14.3656 10.0686 14.2495 9.84468 14.3646 9.68182L15.8294 7.61454L14.2716 3.0433C14.2177 2.88442 14.335 2.71937 14.5041 2.71937H15.8938C16.0744 2.71937 16.2337 2.83789 16.2858 3.01069L17.1125 5.77157L19.0633 2.89854C19.1397 2.78638 19.2665 2.71937 19.4014 2.71937Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7367 7.88076L35.9429 0.207206C35.9614 0.0880928 36.0646 0.000397707 36.1845 0H37.5425C37.6924 0 37.8076 0.134424 37.7839 0.283167L36.5944 7.81852C36.5634 8.01737 36.3924 8.16353 36.1909 8.16353H34.9782C34.8282 8.16353 34.7131 8.0295 34.7367 7.88076ZM25.4991 0.000398247H22.5595C22.3589 0.000398247 22.1878 0.146555 22.1564 0.345011L20.9677 7.88076C20.9441 8.0295 21.0592 8.16353 21.2094 8.16353H22.7176C22.8578 8.16353 22.9778 8.06132 22.9996 7.92212L23.3369 5.78604C23.368 5.58698 23.5394 5.44103 23.7401 5.44103H24.67C26.6065 5.44103 27.7236 4.50423 28.0157 2.64793C28.1476 1.83581 28.0209 1.19769 27.6406 0.75067C27.2232 0.260099 26.4828 0.000398247 25.4991 0.000398247ZM25.8382 2.75273C25.6779 3.80725 24.8721 3.80725 24.0925 3.80725H23.6494L23.961 1.8378C23.9795 1.71889 24.0817 1.63119 24.2024 1.63119H24.4057C24.9361 1.63119 25.4373 1.63119 25.6958 1.93365C25.8501 2.11421 25.8971 2.38226 25.8382 2.75273ZM34.2839 2.71892H32.8774C32.7565 2.71892 32.6539 2.80662 32.6358 2.92573L32.5735 3.31926L32.4747 3.17668C32.17 2.73443 31.4911 2.58668 30.8129 2.58668C29.2575 2.58668 27.9296 3.76449 27.6708 5.41677C27.5368 6.24082 27.7271 7.02887 28.1947 7.5785C28.6245 8.08379 29.2374 8.29437 29.9675 8.29437C31.2206 8.29437 31.9155 7.48862 31.9155 7.48862L31.8528 7.87976C31.8292 8.0293 31.9443 8.16333 32.0952 8.16333H33.3625C33.5632 8.16333 33.7342 8.01717 33.7656 7.81832L34.5267 3.00169C34.55 2.85355 34.4348 2.71892 34.2839 2.71892ZM32.3222 5.45793C32.1871 6.26189 31.5481 6.80158 30.7341 6.80158C30.3262 6.80158 29.9989 6.67054 29.7891 6.42217C29.5811 6.17559 29.5029 5.82442 29.5683 5.43347C29.6958 4.63627 30.3439 4.07908 31.1454 4.07908C31.5452 4.07908 31.8699 4.21171 32.0841 4.46227C32.2993 4.71541 32.3844 5.06857 32.3222 5.45793Z"
        fill="white"
      />
    </svg>
  );
}
