import './Spinner.scss';

import classNames from 'classnames';

type SpinnerProps = {
  color?: 'white' | 'black';
  size?: number | string;
  strokeWidth?: number;
};

const className = 'c-Spinner';

const Spinner = ({
  color = 'black',
  size = 50,
  strokeWidth = 3,
}: SpinnerProps) => {
  return (
    <div
      className={classNames(className, `${className}--${color}`)}
      style={{ width: size, height: size, borderWidth: strokeWidth }}
    />
  );
};

export default Spinner;
