import { getCountryNameTranslation, Resource } from '@travelwin/core';

export const GLOBAL_DISPLAY_ORDER = -100;

/**
 * Temporary type used for filtering and sorting options
 */
export interface Option extends Resource {
  text: string;
}

const sortCountries = (
  countries: Option[],
  inputValue: string | null,
): Resource[] => {
  let compareFunction: (a: Option, b: Option) => number;
  if (inputValue) {
    compareFunction = (a, b) => {
      const text1 = a.text || '';
      const text2 = b.text || '';
      const text1StartsWith = isTextStartWith(text1, inputValue);
      const text2StartsWith = isTextStartWith(text2, inputValue);

      if (text1StartsWith && !text2StartsWith) return -1; // item1 comes before item2
      if (!text1StartsWith && text2StartsWith) return 1; // item2 comes before item1
      return text1.localeCompare(text2); // Sort alphabetically
    };
  } else {
    compareFunction = (a, b) => {
      const text1 = a.text || '';
      const text2 = b.text || '';
      const order1 = a?.display_order || 0;
      const order2 = b?.display_order || 0;
      if (order1 < 0 && order2 >= 0) {
        return 1;
      } else if (order1 >= 0 && order2 < 0) {
        return -1;
      } else if (order1 === GLOBAL_DISPLAY_ORDER) {
        return 1;
      } else if (order2 === GLOBAL_DISPLAY_ORDER) {
        return -1;
      }
      return text1.localeCompare(text2);
    };
  }
  return countries.sort(compareFunction);
};

const filterItems = (data: Option[], inputValue: string | null) => {
  if (!inputValue) return data;

  return data.filter(
    (item) =>
      (item.text || '').toLowerCase().indexOf(inputValue.toLowerCase().trim()) >
      -1,
  );
};

const isTextStartWith = (text: string, inputValue: string) =>
  text.toLowerCase().startsWith(inputValue.toLowerCase());

export const prepareOptions = (
  data: Resource[],
  inputValue: string | null,
): Resource[] => {
  const translatedData: Option[] = data.map((item) => {
    return {
      ...item,
      text: getCountryNameTranslation(item.description),
    };
  });
  const filteredData = filterItems(translatedData, inputValue);
  return sortCountries(filteredData, inputValue);
};
