const X = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1761 4.74268H19.9362L13.9061 11.5201L21 20.7427H15.4456L11.0951 15.1493L6.11723 20.7427H3.35544L9.80517 13.4935L3 4.74268H8.69545L12.6279 9.8553L17.1761 4.74268ZM16.2073 19.1181H17.7368L7.86441 6.28196H6.2232L16.2073 19.1181Z"
      fill="white"
    />
  </svg>
);

export default X;
