import './Typeahead.scss';

import { CaretDown, CaretUp, MagnifyingGlass, X } from '@phosphor-icons/react';
import {
  ControllerButton,
  CountryFlag,
  getCountryNameTranslation,
  Resource,
} from '@travelwin/core';
import classNames from 'classnames';
import Downshift, { PropGetters } from 'downshift';
import { useId } from 'react';

import { GLOBAL_DISPLAY_ORDER, prepareOptions } from '../../utils/countryUtils';

const className = 'c-Typeahead';

interface TypeaheadProps {
  selectedOption: Resource | null;
  data: Resource[];
  onChange: (selectedValue: Resource | null) => void;
  placeholder?: string;
  countries?: boolean;
  emptyFilterMessage: string;
}

const Typeahead = ({
  selectedOption,
  data,
  onChange,
  placeholder,
  countries = false,
  emptyFilterMessage,
}: TypeaheadProps) => {
  const id = useId();

  const inputActiveClass = (isOpen: boolean, selectedItem: Resource | null) =>
    `${className}__input ${isOpen ? `${className}__input--active` : ''} ${
      countries && selectedItem ? `${className}__input--flag` : ''
    }`;

  const renderSelectedItem = (selectedItem: Resource | null) => {
    return (
      selectedItem && (
        <CountryFlag countryCode={selectedItem.alias.toLowerCase()} />
      )
    );
  };

  const onItemChange = (selectedValue: Resource | null) => {
    onChange(selectedValue);
  };

  const itemToString = (i: Resource | null): string => {
    return i?.description ? getCountryNameTranslation(i.description) : '';
  };

  const renderItem = (i: Resource) => {
    return (
      <>
        {itemToString(i)}
        {countries && <CountryFlag countryCode={i.alias.toLowerCase()} />}{' '}
      </>
    );
  };

  const renderOptions = (
    options: Resource[],
    getItemProps: PropGetters<Resource>['getItemProps'],
    highlightedIndex: number | null,
    inputValue: string | null,
    selectedItem: Resource | null,
  ) => {
    if (options.length < 1) {
      return (
        <div className={`${className}__item--disabled`}>
          {emptyFilterMessage}
        </div>
      );
    }
    const firstRegionalOrder = options.find((o) => (o?.display_order || 0) < 0)
      ?.display_order;

    return options.map((item, index) => (
      <div key={item.id}>
        {(item.display_order === firstRegionalOrder ||
          item.display_order === GLOBAL_DISPLAY_ORDER) &&
          !inputValue && (
            <div
              key={`divider-${item.id}`}
              className={`${className}__item-divider`}
            ></div>
          )}
        <div
          data-testid={`input-typeahead-item-${index}`}
          className={classNames(
            `${className}__item`,
            highlightedIndex === index && `${className}__item--active`,
            selectedItem === item && `${className}__item--selected`,
          )}
          {...getItemProps({
            item,
            index,
          })}
        >
          {renderItem(item)}
        </div>
      </div>
    ));
  };

  const getMenuActiveClasses = (isOpen: boolean, disabled: boolean) => {
    return `${className}__menu${isOpen ? ` ${className}__menu--open` : ''}${
      disabled ? ` ${className}__menu--open--disabled` : ''
    }`;
  };

  return (
    <div className={className}>
      <Downshift
        id={id}
        itemToString={itemToString}
        selectedItem={selectedOption}
        onChange={onItemChange}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          isOpen,
          clearSelection,
          selectedItem,
          highlightedIndex,
          inputValue,
          toggleMenu,
        }) => {
          const preparedData = prepareOptions(data, inputValue);
          return (
            <div className={`${className}__input-wrapper`}>
              <div className={`${className}__relative-container`}>
                {renderSelectedItem(selectedItem)}
                {!selectedItem ? (
                  <span
                    onClick={() => toggleMenu()}
                    className={`${className}__search-icon`}
                  >
                    <MagnifyingGlass
                      size={24}
                      weight="bold"
                      className={`${className}__icon`}
                    />
                  </span>
                ) : null}
                <input
                  className={inputActiveClass(isOpen, selectedItem)}
                  {...getInputProps({
                    placeholder,
                    onChange: (e) => {
                      if (e.currentTarget.value === '') clearSelection();
                    },
                  })}
                  data-testid="input-typeahead"
                />
                {selectedItem ? (
                  <ControllerButton
                    onClick={() => clearSelection()}
                    aria-label="clear selection"
                  >
                    <X
                      size={24}
                      weight="bold"
                      className={`${className}__icon`}
                    />
                  </ControllerButton>
                ) : (
                  <ControllerButton {...getToggleButtonProps()}>
                    {isOpen ? (
                      <CaretUp
                        size={24}
                        weight="bold"
                        className={`${className}__icon`}
                      />
                    ) : (
                      <CaretDown
                        size={24}
                        weight="bold"
                        className={`${className}__icon`}
                      />
                    )}
                  </ControllerButton>
                )}
              </div>
              <div className={`${className}__relative-list-container`}>
                <div
                  className={getMenuActiveClasses(
                    isOpen,
                    preparedData?.length < 1,
                  )}
                >
                  {isOpen
                    ? renderOptions(
                        preparedData,
                        getItemProps,
                        highlightedIndex,
                        inputValue,
                        selectedItem,
                      )
                    : null}
                </div>
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default Typeahead;
