import { Auth } from '@aws-amplify/auth';
import {
  API_VERSION_HEADER,
  getCurrentAppVersion,
  getErrorMessage,
} from '@travelwin/core';
import { v4 as uuidv4 } from 'uuid';

import { getCurrentFlavoredLocale } from '../../i18n';
import {
  apiCompatibilityChecker,
  checkVersionForWebOnly,
} from './eCommerceVersionCheck';
import { getApiUrl, getAppOrigin } from './requests';

export class HttpError extends Error {
  constructor(
    public path: string,
    public method: string,
    public status: number,
    public body: any,
    public clientTransactionId: string | null,
    message?: string,
  ) {
    super(message);
  }

  public isNotFound() {
    return this.status === 404;
  }
}

export const isHttpError = (error: unknown): error is HttpError => {
  return error instanceof HttpError;
};

export const isNotFoundError = (error: unknown): boolean => {
  return isHttpError(error) && error.isNotFound();
};

export const request = async (path: string, options?: RequestInit) => {
  let accessToken = '';

  await Auth.currentSession()
    .then((data: any) => (accessToken = data.accessToken.jwtToken))
    .catch((err: any) => {
      return err;
    });

  const clientTransactionId = uuidv4();
  const optionsWithHeader = {
    ...options,
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...(typeof window === 'undefined'
        ? { Origin: process.env.REACT_APP_CONFIG_ORIGIN }
        : {}),
      'X-LOCALE': getCurrentFlavoredLocale(),
      'X-APP-ORIGIN': getAppOrigin(),
      'X-APP-VERSION': getCurrentAppVersion(),
      'X-DAPX-CLIENT-TRANSACTION-ID': clientTransactionId,
      ...options?.headers,
    },
  };

  const basePath = getApiUrl() || '/';

  const response = await fetch(`${basePath}${path}`, optionsWithHeader);

  checkVersionForWebOnly(response.headers.get(API_VERSION_HEADER));

  if (response.status === 401) {
    await Auth.signOut();
    window.location.reload();
    return;
  }

  let body;
  try {
    body = await response.json();
  } catch (e) {
    /* empty */
  }

  apiCompatibilityChecker.check(response.status, body);

  if (response.ok) {
    return body;
  }

  throw new HttpError(
    path,
    options?.method ?? 'GET',
    response.status,
    body,
    clientTransactionId,
    getErrorMessage(body),
  );
};
