import { Auth } from '@aws-amplify/auth';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { captureException } from '@sentry/capacitor';
import {
  getConfig,
  getCurrentAppVersion,
  getFlavoredTranslations,
  Resource,
  SUPPORTED_LOCALE,
  SUPPORTED_LOCALES_SUBSET,
  supportedLocales,
} from '@travelwin/core';
import { add } from 'date-fns';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import Cookies from 'js-cookie';
import { unique } from 'radash';
import { initReactI18next } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { getApiUrl, getAppOrigin } from '../services/helpers/requests';
import { isDefaultBrand, ShopBrand } from '../utils';
import { isRemix } from '../utils/ssrUtils';
import { getCookieDomain } from '../utils/urlUtils';

const LANG_STORAGE_PARAM = 'lang';

const languageDetector = new LanguageDetector();

export const getCurrentLocale = (): SUPPORTED_LOCALE => {
  return i18n.language as SUPPORTED_LOCALE;
};

export class TranslationsLoadError extends Error {
  responseStatus?: number;
  responseBody?: unknown;

  constructor({
    cause,
    responseStatus,
    responseBody,
  }: {
    cause?: unknown;
    responseStatus?: number;
    responseBody?: unknown;
  } = {}) {
    super('Could not fetch supported languages', { cause });
    this.name = 'TranslationsLoadError';
    this.responseStatus = responseStatus;
    this.responseBody = responseBody;
  }
}

/**
 * Flavored locale is used in our BE. It contains language and organisation specific slug,
 * which is used e.g. to resolve email theme.
 */
export const getCurrentFlavoredLocale = (): string => {
  if (isRemix()) {
    return 'en';
  }

  const { WHITELABEL_BRAND } = getConfig();

  return isDefaultBrand(WHITELABEL_BRAND)
    ? i18n.language
    : `${i18n.language}_${WHITELABEL_BRAND}`;
};

export const getSupportedLanguages = (): SUPPORTED_LOCALES_SUBSET => {
  const currentLocales = i18n.options.supportedLngs;

  if (!Array.isArray(currentLocales)) {
    return [];
  }

  return currentLocales.filter((locale) => supportedLocales.includes(locale));
};

export const fetchSupportedLanguages =
  async (): Promise<SUPPORTED_LOCALES_SUBSET> => {
    const clientTransactionId = uuidv4();
    const response = await fetch(`${getApiUrl()}ecommerce/resources/locales`, {
      headers: {
        'X-APP-ORIGIN': getAppOrigin(),
        'X-APP-VERSION': getCurrentAppVersion(),
        'X-DAPX-CLIENT-TRANSACTION-ID': clientTransactionId,
      },
    });

    let body;
    try {
      body = await response.json();
    } catch (e) {
      /* empty */
    }

    if (!response.ok) {
      throw new TranslationsLoadError({
        responseStatus: response.status,
        responseBody: body,
      });
    }

    return body.map((l: Resource) => l.alias);
  };

export const changeLanguage = async (language: string) => {
  languageDetector.cacheUserLanguage(language);

  let user;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (e) {
    /* empty */
  }

  if (user) {
    try {
      await Auth.updateUserAttributes(user, {
        locale: language,
      });
    } catch (e) {
      captureException(e);
    }
  }

  window.location.reload();
};

export const configure = async (
  whitelabelBrand: ShopBrand,
  supportedLngs: SUPPORTED_LOCALES_SUBSET,
) => {
  // removes old lang cookie, fixes language switch
  if (location.hostname !== 'localhost') {
    Cookies.remove('lang');
  }

  const preference = await Preferences.get({ key: LANG_STORAGE_PARAM });

  languageDetector.addDetector({
    name: 'preferences',
    lookup: () => {
      return preference.value ?? undefined;
    },
    cacheUserLanguage: (lng) => {
      Preferences.set({
        key: LANG_STORAGE_PARAM,
        value: lng,
      });
    },
  });

  return new Promise<void>((resolve, reject) => {
    i18n
      .use(languageDetector)
      .use(HttpApi)
      .use(initReactI18next)
      .init<HttpBackendOptions>(
        {
          supportedLngs: unique([...supportedLngs, 'en']),
          detection: {
            order: [
              Capacitor.isNativePlatform() ? 'preferences' : 'cookie',
              'navigator',
            ],
            caches: [Capacitor.isNativePlatform() ? 'preferences' : 'cookie'],
            lookupCookie: LANG_STORAGE_PARAM,
            cookieOptions: {
              sameSite: 'strict',
              expires: add(new Date(), { years: 1 }),
              domain: getCookieDomain(),
            },
          },
          keySeparator: false,
          ns: ['ecommerce', 'countries'],
          defaultNS: 'ecommerce',
          backend: {
            loadPath: '/i18n/{{ns}}_{{lng}}.json',
            parse: (data: string) => {
              const translations = JSON.parse(data);
              return getFlavoredTranslations(translations, whitelabelBrand);
            },
          },
          fallbackLng: 'en',
          interpolation: {
            escapeValue: false, // not needed for react, it escapes by default
          },
          react: {
            useSuspense: false,
          },
        },
        (error) => {
          if (!error) {
            resolve();
          } else {
            reject(Error(`Translations not loaded, Error: ${error}`));
          }
        },
      );
  });
};
