import { OrderType } from '@travelwin/core';

import { request as authorizedRequests } from '../helpers/authorizedRequests';
import { request } from '../helpers/requests';

const baseUri = 'ecommerce';

export const registerContact = (
  email: string,
  marketing_opt_in: boolean,
): Promise<OrderType> => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email, marketing_opt_in }),
  };
  return request(`${baseUri}/register_contact`, options);
};

export const changeMarketingConsent = (value: boolean): Promise<OrderType> => {
  const options = {
    method: 'POST',
  };
  return authorizedRequests(
    `${baseUri}/marketing_consent?value=${value}`,
    options,
  );
};

export const deleteUser = () => {
  const options = {
    method: 'DELETE',
  };

  const path = 'ecommerce/users';

  return authorizedRequests(path, options);
};
