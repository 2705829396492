import { type OrderType, OrderStatus } from '@travelwin/core';

const sortOrderByCreatedDateAsc = (a: OrderType, b: OrderType) => {
  const aUpdatedDate = new Date(a.created_date).valueOf();
  const bUpdatedDate = new Date(b.created_date).valueOf();

  return aUpdatedDate - bUpdatedDate;
};

const sortOrderByCreatedDateDesc = (a: OrderType, b: OrderType) => {
  const aUpdatedDate = new Date(a.created_date).valueOf();
  const bUpdatedDate = new Date(b.created_date).valueOf();

  return bUpdatedDate - aUpdatedDate;
};

const isValidEsim = (order: OrderType) => {
  const orderItem = order?.items?.[0];
  const orderStatus = order?.status?.id as OrderStatus;

  return (
    orderItem &&
    orderStatus &&
    [
      OrderStatus.Completed,
      OrderStatus.Purchased,
      OrderStatus.ProcessingObtainingActivationCode,
      OrderStatus.ProcessingApplyingBundle,
      OrderStatus.Failed,
      OrderStatus.Refunded,
    ].includes(orderStatus)
  );
};

const getEsimIdentifier = (order: OrderType) => {
  if (!isValidEsim(order)) return null;

  const orderItem = order.items[0];
  const isTypeEsim = orderItem?.type?.id === 'ESIM';
  const { ssn } = orderItem;

  return !ssn && isTypeEsim ? `temp_${orderItem.id}` : ssn;
};

const isAddons = (orders: Array<OrderType>) => {
  // The group has at least one bundle order and none of them can be renewed.
  // This means it's either an order group with one or more addons.
  return (
    orders.filter((order) => {
      const orderItem = order.items[0];
      return orderItem?.type?.id === 'BUNDLE';
    }).length > 0 &&
    orders.filter((order) => {
      const { product_offering } = order.items[0];
      return product_offering?.can_renew_bundle;
    }).length === 0
  );
};

export const getEsimsFromOrders = (
  orders: Array<OrderType>,
): Array<OrderType> => {
  const orderGroupsBySsn = orders.reduce<Record<string, Array<OrderType>>>(
    (accumulator, order) => {
      const ssn = getEsimIdentifier(order);
      if (!ssn) return accumulator;

      if (!accumulator[ssn]) {
        accumulator[ssn] = [];
      }

      accumulator[ssn].push(order);
      return accumulator;
    },
    {},
  );

  const myEsimsMap = Object.keys(orderGroupsBySsn)
    .map((ssn: string) => {
      const orders = orderGroupsBySsn[ssn];
      const sortFunc = isAddons(orders)
        ? sortOrderByCreatedDateAsc
        : sortOrderByCreatedDateDesc;
      return orders.sort(sortFunc)[0];
    })
    .sort(sortOrderByCreatedDateDesc);

  return myEsimsMap;
};

export const getAffiliateOrderErrorCode = (errorMsg: string) => {
  return errorMsg === 'Order is already affiliated with this customer'
    ? 'order_already_exists'
    : 'order_not_found';
};
