import { Device, DeviceInfo } from '@capacitor/device';
import { captureException } from '@sentry/capacitor';
import { useEffect, useState } from 'react';
import { gte } from 'semver';

interface NavigatorUAData {
  mobile: boolean;
}

interface ExtendedNavigator extends Navigator {
  userAgentData?: NavigatorUAData;
}

export const useDeviceOS = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>(
    undefined,
  );
  const [isMobileWeb, setIsMobileWeb] = useState<boolean>(false);
  const [isSamsungBrowser, setIsSamsungBrowser] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const deviceInfo = await Device.getInfo();
        setDeviceInfo(deviceInfo);
      } catch (error) {
        captureException(error);
      }
    })();

    detectMobileBrowser();
    detectSamsungBrowser();
  }, []);

  const detectMobileBrowser = () => {
    const navigatorTyped = navigator as ExtendedNavigator;
    const isMobileUA =
      navigatorTyped.userAgentData?.mobile ??
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent.toLowerCase(),
      );
    const isSmallScreen = window.innerWidth <= 768;
    const isMobileBrowser = isMobileUA && isSmallScreen;
    setIsMobileWeb(isMobileBrowser);
  };

  const detectSamsungBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isSamsungDevice = /samsung/i.test(userAgent);
    setIsSamsungBrowser(isSamsungDevice);
  };

  const operatingSystem = deviceInfo?.operatingSystem;
  const deviceOS = operatingSystem;
  const isiOSOS = operatingSystem === 'ios';
  const isAndroidOS = operatingSystem === 'android';
  const isMobileOS = isiOSOS || isAndroidOS;
  const deviceOSVersion = deviceInfo?.osVersion;
  const isSamsung =
    deviceInfo?.manufacturer?.toLowerCase() === 'samsung' || isSamsungBrowser;

  const formatVersion = (version: string) => {
    const count = version.split('.').length; // We're looking for a 'X.Y.Z' value
    if (count === 1) {
      return `${version}.0.0`;
    } else if (count === 2) {
      return `${version}.0`;
    }

    return version;
  };

  const isOSVersionGTE = (version: string) => {
    try {
      if (deviceOSVersion) {
        const formattedOSVersion = formatVersion(deviceOSVersion);
        const formattedCheckVersion = formatVersion(version);

        return gte(formattedOSVersion, formattedCheckVersion);
      }
    } catch (e) {
      captureException(e);
    }
    return false;
  };

  return {
    isiOSOS,
    isAndroidOS,
    isMobileOS,
    isSamsung,
    deviceOS,
    deviceOSVersion,
    isOSVersionGTE,
    isMobileWeb,
  };
};
