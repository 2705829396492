/**
 * Supported locales should be synchronised with backend `Locale` enum
 */
export const supportedLocales = [
  'de',
  'el',
  'en',
  'es',
  'fr',
  'it',
  'nl',
  'pl',
  'pt',
  'ro',
  'tr',
  'pt-BR',
] as const;
export type SUPPORTED_LOCALES = typeof supportedLocales;
export type SUPPORTED_LOCALE = SUPPORTED_LOCALES[number];
export type SUPPORTED_LOCALES_SUBSET = ReadonlyArray<SUPPORTED_LOCALE>;
