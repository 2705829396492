import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { useEffect } from 'react';

function CapacitorFirebase() {
  const initAnalytics = async () => {
    await FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  };

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    initAnalytics();
  }, []);

  return null;
}

export default CapacitorFirebase;
