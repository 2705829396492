import './MobileNavFooter.scss';

import { Cpu, House, Lifebuoy, User } from '@phosphor-icons/react';
import { useConfig } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import { isDefaultBrand } from '../../utils/brandUtils';
import MobileNavLink from '../MobileNavLink/MobileNavLink';

const className = 'c-MobileNavFooter';

const MobileNavFooter = () => {
  const { t } = useTranslation();
  const { WHITELABEL_BRAND } = useConfig();

  return (
    <div className={className} data-testid="nav-footer">
      <div className={`${className}__links`}>
        <MobileNavLink to="/" end data-testid="link-home">
          <House size={24} weight="bold" />
          <div className={`${className}__icon-text`}>
            {t('navbar.home', 'Home')}
          </div>
        </MobileNavLink>

        <MobileNavLink to="/orders" data-testid="link-my-eSIM">
          <Cpu size={24} weight="bold" />
          <div className={`${className}__icon-text`}>
            {t('navbar.my_esim', 'My eSIM')}
          </div>
        </MobileNavLink>

        <MobileNavLink to="/my-account" data-testid="link-account">
          <User size={24} weight="bold" />
          <div className={`${className}__icon-text`}>
            {t('navbar.account', 'Account')}
          </div>
        </MobileNavLink>

        {isDefaultBrand(WHITELABEL_BRAND) && (
          <MobileNavLink to="/help" data-testid="link-help">
            <Lifebuoy size={24} weight="fill" />
            <div className={`${className}__icon-text`}>
              {t('navbar.help', 'Help')}
            </div>
          </MobileNavLink>
        )}
      </div>
    </div>
  );
};

export default MobileNavFooter;
