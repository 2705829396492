import './MobileNavLink.scss';

import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

const className = 'c-MobileNavLink';

const MobileNavLink = ({
  children,
  ...props
}: Pick<NavLinkProps, 'to' | 'end' | 'children'>) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(`${className}__link`, {
          [`${className}__link--active`]: isActive,
        })
      }
    >
      {children}
    </NavLink>
  );
};

export default MobileNavLink;
