const Facebook = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12.8038C22 7.24719 17.5229 2.74268 12 2.74268C6.47715 2.74268 2 7.24719 2 12.8038C2 17.8255 5.65684 21.9879 10.4375 22.7427V15.7121H7.89844V12.8038H10.4375V10.5872C10.4375 8.06564 11.9305 6.6728 14.2146 6.6728C15.3088 6.6728 16.4531 6.86931 16.4531 6.86931V9.34529H15.1922C13.95 9.34529 13.5625 10.1209 13.5625 10.9166V12.8038H16.3359L15.8926 15.7121H13.5625V22.7427C18.3432 21.9879 22 17.8257 22 12.8038Z"
      fill="white"
    />
  </svg>
);

export default Facebook;
