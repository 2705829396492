import './ProcessingIcon.scss';

const ProcessingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <g id="ProcessingIcon" transform="translate(63.999995,64.050026) rotate(0)">
      <g transform="translate(-63.999994,-64.050026)">
        <path
          d="M29.9,47.7c-2.4,4.9-3.7,10.5-3.7,16.3c0,20.9,16.9,37.8,37.8,37.8s37.8-16.9,37.8-37.8c0-20.1-15.7-36.5-35.5-37.7"
          transform="matrix(.907953-.419072 0.419072 0.907953-20.950563 32.716244)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="c-PrimaryColorStroke"
        />
        <path
          d="M72.5,34.7L64,26.2l8.5-8.5"
          transform="matrix(0 1-1 0 52.400015-4.150011)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="c-PrimaryColorStroke"
        />
      </g>
    </g>
  </svg>
);

export default ProcessingIcon;
