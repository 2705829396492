import type { ProductOffering } from './Product';
import type { Resource } from './Resources';

export enum OrderStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  PaymentIntent = 'PAYMENT_INTENT',
  AwaitingPayment = 'AWAITING_PAYMENT',
  ProcessingApplyingTopUp = 'PROCESSING_APPLYING_TOP_UP',
  ProcessingApplyingBundle = 'PROCESSING_APPLYING_BUNDLE',
  ProcessingObtainingActivationCode = 'PROCESSING_OBTAINING_ACTIVATION_CODE',
  ProcessingWaitingNetworkActivation = 'PROCESSING_WAITING_NETWORK_ACTIVATION',
  Purchased = 'PURCHASED',
  Refunded = 'REFUNDED',
}

export type OrderItemType = {
  id: string;
  version: number;
  country_id: string;
  activation_key: string;
  activation_date: string;
  updated_date: string;
  expiry_date: string;
  expiry_reminder_sent: boolean;
  type: Resource;
  product_offering: ProductOffering;
  /**
   * @deprecated use #product_price_gross instead
   */
  price: number;
  msisdn: string;
  ssn: string;
  qr_code_string: string;
  confirmation_code: string;
  puk1: string;
  pin1: string;
  smdp_plus_url: string;
  order_id: string;
  sku: string;
  faulty?: boolean;
  faulty_reason?: string | null;
  faulty_reason_type?: {
    description: string;
  };
  product_price_gross: number;
  product_price_net: number;
  product_vat_percentage: number;
  product_vat: number;
  tuas_price_gross: number;
  tuas_price_net: number;
  tuas_vat_percentage: number;
  tuas_vat: number;
  total_price_gross: number;
  total_price_net: number;
  total_vat: number;
  initial_data?: number;
  remaining_data?: number;
};

export type OrderType = {
  id: string;
  created_date: string;
  updated_date: string;
  travel_date: string;
  retailer: Resource;
  currency: Resource;
  total_price: number;
  total_vat: number;
  locale?: string | null;
  status: Resource<`${OrderStatus}`>;
  refund_reason?: Resource<
    'ORDER_FAILED' | 'ACTIVATION_FAILED' | 'USER_REQUESTED'
  >;
  order_number: string;
  created_by_id?: string;
  external_user_email?: string;
  external_user_id: string;
  external_user_name: string;
  external_user_phone: string;
  retail_agent_email: string;
  retail_agent_id: string;
  retail_agent_user_name: string;
  payment_reference: string;
  payment_type: Resource;
  payment_details: OrderPaymentType;
  payments: Array<OrderPaymentsType>;
  campaign_parameters: CampaignParameters | null;
  items: Array<OrderItemType>;
};

export interface OrderPaymentsType {
  payment_type: Partial<Resource<'CARD' | 'PAYPAL' | 'VOUCHER'>>;
  payment_reference?: string;
  payment_transaction_id?: string;
  price: number;
  voucher_serial?: null;
}

interface OrderPaymentType {
  payer_id?: string;
  payment_approval_url?: string;
  client_secret?: string;
  cardholder_name?: string;
  pa_req?: string;
  md?: string;
  paRes?: string;
  acs_url?: string;
  oneusetoken?: string;
  receipt_dd?: string;
}

export interface CampaignParameters {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
  awin_awc: string | null;
}

export interface FreeTrialData {
  order_id: string;
  status: 'NEW' | 'USED' | 'NOT_ALLOWED' | 'NOT_ALLOWED_EMAIL';
  reason: string;
}
