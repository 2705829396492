import { Auth, withSSRContext } from 'aws-amplify';
import cookie from 'cookie';

import { ecommerceConfigSchema } from '../components/EcommerceConfigProvider/ecommerceConfigSchema';
import { request } from '../services/helpers/requests';

export const isRemix = () => {
  return (
    typeof window === 'undefined' || typeof __remixManifest !== 'undefined'
  );
};

export const getUniteBaseUrl = () => {
  if (typeof window === 'undefined') {
    return process.env.REACT_APP_API_ENDPOINT_ECOMMERCE;
  } else {
    return window.ENV.REACT_APP_API_ENDPOINT_ECOMMERCE;
  }
};

export const getCmsBaseUrl = () => {
  if (typeof window === 'undefined') {
    return process.env.REACT_APP_CMS_ENDPOINT;
  } else {
    return window.ENV.REACT_APP_CMS_ENDPOINT;
  }
};

const getAuthInstance = (request: Request): typeof Auth => {
  const Amplify = withSSRContext({
    req: { headers: { cookie: request.headers.get('Cookie') } },
  });

  return Amplify.Auth;
};

export const getAccessToken = async (request: Request) => {
  try {
    const auth = getAuthInstance(request);

    const session = await auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    return token;
  } catch {
    return null;
  }
};

export const getUser = async (request: Request) => {
  try {
    const auth = getAuthInstance(request);

    const user = await auth.currentAuthenticatedUser();

    return user;
  } catch {
    return null;
  }
};

export const getCurrencyCookie = (cookieHeader: string | null) => {
  if (!cookieHeader) return undefined;
  const cookies = cookie.parse(cookieHeader);
  return cookies.currency;
};

export const getConfig = async () => {
  const response = await request('settings/ui_settings');

  const config = await ecommerceConfigSchema.validate(response);

  return config;
};
