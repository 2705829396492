import './Navbar.scss';

import { CaretDown } from '@phosphor-icons/react';
import { getCurrencyCode, getLabel, Icon, useConfig } from '@travelwin/core';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrency } from '../../context/Currency';
import { NavItem, useNavbarItems } from '../../hooks';
import { useExternalHomepageConfig } from '../../hooks/useExternalHomepageConfig';
import { FreeTrialBanner } from '../../pages/FreeTrialBanner/FreeTrialBanner';
import { getBrandSpecificImage } from '../../utils';
import CurrencySelector from '../CurrencySelector';
import { Link, useNavigate } from '../Link';
import { ShowTo } from '../ShowTo';
import CountrySwitch from './CountrySwitch/CountrySwitch';
import { LanguageSwitch } from './LanguageSwitch/LanguageSwitch';

const className = 'c-EcommerceNavbar';

const Navbar = () => {
  const [expandedSubMenuId, setExpandedSubMenuId] = useState('');
  const [isBurgerMenuExpanded, setIsBurgerMenuExpanded] = useState(false);
  const [isActiveScroll, setIsActiveScroll] = useState(false);
  /*
  `useRef` may seem more appropriate for this,
  but it caused an issue with Radix dropdown not showing without an additional render.
  
  We use `useState` instead, which is suggested in the docs (for different component, but seems to be applicable for Select as well)
  https://www.radix-ui.com/primitives/docs/components/alert-dialog#custom-portal-container
  */
  const [headerRef, setHeaderRef] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();
  const {
    WHITELABEL_BRAND,
    BRAND_NAME,
    WHATSAPP_CONTACT_NUMBER,
    PAYMENT_DEFAULT_CURRENCY,
    PAYMENT_SUPPORTED_CURRENCIES,
  } = useConfig();
  const { currency, setCurrency } = useCurrency();
  const { t } = useTranslation();
  const { navbarItems } = useNavbarItems();

  const { shouldRedirectToExternalHomepage, getHomepageUrl } =
    useExternalHomepageConfig();

  useEffect(() => {
    const handleScroll = () => {
      setIsActiveScroll(window.scrollY !== 0);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavItemClick = (item: NavItem) => {
    const { to, items } = item;

    const hasSubMenu = Array.isArray(items) && items.length > 0;

    if (to && !hasSubMenu) {
      setIsBurgerMenuExpanded(false);
      navItemOnClick(item);
    }

    if (items) {
      const id = item.id === expandedSubMenuId ? '' : item.id;
      setExpandedSubMenuId(id);
    }
  };

  const navItemOnClick = (item: NavItem) => {
    const { to, ecommerceRoute, items } = item;
    if (!to) return;

    const redirect = (link: string, ecommerceRoute: boolean) => {
      const hasSubMenu = Array.isArray(items) && items.length > 0;
      if (!hasSubMenu) {
        if (ecommerceRoute) {
          navigate(link);
        } else {
          window.location.href = link;
        }
      }
    };

    redirect(to, !!ecommerceRoute);
  };

  const renderMenu = () => {
    return navbarItems.map((item: NavItem, index: number) => {
      if (!item.visible) return null;
      return (
        <div key={index} className={`${className}__menu-item`}>
          <button
            type="button"
            className={`${className}__menu-item-link`}
            onClick={() => handleNavItemClick(item)}
            data-testid={item['data-testid']}
          >
            {item.label}
            {Array.isArray(item.items) ? (
              <CaretDown
                size={16}
                weight="bold"
                className={`${className}__menu-item-link-caret`}
              />
            ) : null}
          </button>
          {Array.isArray(item.items) &&
            item.items.length > 0 &&
            renderMenuSubItems(item.items)}
        </div>
      );
    });
  };

  const renderMenuSubItems = (items: Array<NavItem>) => {
    return (
      <div className={`${className}__menu-item-children`}>
        {items.map((item: NavItem, index: number) => {
          if (!item.visible) return null;

          return (
            <div
              key={index}
              className={`${className}__submenu-item`}
              onClick={() => handleNavItemClick(item)}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBurgerMenuItem = (item: NavItem) => {
    const { id, label, items } = item;
    const burgerItemClassName = `${className}__burger-submenu-item`;
    const isActive = expandedSubMenuId === id;

    if (!item.visible) return null;

    return (
      <div
        className={classNames({
          [`${burgerItemClassName}--highlight`]: isActive,
        })}
        onClick={() => handleNavItemClick(item)}
      >
        <div className={`${burgerItemClassName}__title`}>
          {label}
          {items && (
            <CaretDown
              size={24}
              weight="bold"
              className={`${burgerItemClassName}__caret`}
            />
          )}
        </div>
        {isActive && renderBurgerSubmenu(items)}
      </div>
    );
  };

  const renderBurgerSubmenu = (items: Array<NavItem> = []) => {
    const burgerItemClassName = `${className}__burger-submenu-item`;

    return items.map((item: NavItem, index: number) => {
      if (!item.visible) return null;

      return (
        <div
          key={index}
          className={`${burgerItemClassName}__subitem`}
          onClick={() => handleNavItemClick(item)}
        >
          <span className={`${burgerItemClassName}__subitem-title`}>
            {item.label}
          </span>
        </div>
      );
    });
  };

  const renderBurgerMenuItems = () => {
    return (
      <div
        className={classNames(`${className}__burger-submenu`, {
          [`${className}__burger-submenu--expanded`]: isBurgerMenuExpanded,
        })}
      >
        {navbarItems.map((item: NavItem, index: number) => {
          if (!item.visible) return null;
          return (
            <div key={index} className={`${className}__burger-submenu-item`}>
              {renderBurgerMenuItem(item)}
            </div>
          );
        })}
      </div>
    );
  };

  const brandImage = (
    <img
      className={`${className}__brand-image`}
      src={getBrandSpecificImage('logo', WHITELABEL_BRAND)}
      alt={t('logo.altText', '{{brandName}} Logo', { brandName: BRAND_NAME })}
      width={128}
      height={64}
      data-testid="link-home"
    />
  );

  const selectedCurrency = PAYMENT_SUPPORTED_CURRENCIES?.length
    ? currency ?? PAYMENT_DEFAULT_CURRENCY ?? PAYMENT_SUPPORTED_CURRENCIES[0]
    : undefined;

  return (
    <header
      className={classNames(`${className}`, {
        [`${className}--scroll`]: isActiveScroll,
      })}
      ref={setHeaderRef}
    >
      <ShowTo brand="simlocal">
        <div className={`${className}__contactBar`}>
          <div
            className={`${className}__contactBar store-locator`}
            onClick={() => {
              window.location.href = getHomepageUrl('/store-locations');
            }}
          >
            <Icon name="storeFront" />
            <div>{t('navbar.storeLocator', 'Store Locator')}</div>
          </div>
          <div>
            {WHATSAPP_CONTACT_NUMBER ? (
              <a
                className={`${className}__contactBar whatsapp`}
                target="_BLANK"
                rel="noreferrer"
                href={`https://wa.me/${WHATSAPP_CONTACT_NUMBER}`}
              >
                {t('navbar.whatsappNumber', 'WhatsApp: {{number}}', {
                  number: WHATSAPP_CONTACT_NUMBER,
                })}
              </a>
            ) : null}
          </div>
        </div>
      </ShowTo>
      <div className={`${className}__container`}>
        {shouldRedirectToExternalHomepage ? (
          <a href={getHomepageUrl()} className={`${className}__brand`}>
            {brandImage}
          </a>
        ) : (
          <Link to="/" className={`${className}__brand`}>
            {brandImage}
          </Link>
        )}
        <div className={`${className}__menu`}>{renderMenu()}</div>
        <div className={`${className}__site-preferences`}>
          <LanguageSwitch
            className={`${className}__language-switch`}
            container={headerRef}
          />
          {selectedCurrency ? (
            <CurrencySelector
              onChange={setCurrency}
              value={selectedCurrency}
              rootClassName={`${className}__currency-switch`}
            >
              <CurrencySelector.Trigger>
                <CurrencySelector.Selected>
                  {getCurrencyCode(selectedCurrency)}
                </CurrencySelector.Selected>
              </CurrencySelector.Trigger>
              <CurrencySelector.Choices container={headerRef}>
                {PAYMENT_SUPPORTED_CURRENCIES?.map((choice) => (
                  <CurrencySelector.Choice value={choice} key={choice}>
                    {getLabel(choice)}
                  </CurrencySelector.Choice>
                ))}
              </CurrencySelector.Choices>
            </CurrencySelector>
          ) : null}
        </div>

        <CountrySwitch />
        <div className={`${className}__free-trial-button`}>
          <FreeTrialBanner variant="navbar" />
        </div>
        <button
          type="button"
          className={classNames(`${className}__burger-button`, {
            [`${className}__burger-button--expanded`]: isBurgerMenuExpanded,
          })}
          onClick={() => setIsBurgerMenuExpanded((value) => !value)}
        >
          {t('navbar.menu', 'Menu')}
        </button>
      </div>
      {renderBurgerMenuItems()}
    </header>
  );
};

export default Navbar;
