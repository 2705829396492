export const GA4_EVENT = {
  BeginCheckout: 'begin_checkout',
  Purchase: 'purchase',
  AddToCart: 'add_to_cart',
  ViewItemList: 'view_item_list',
  Search: 'search',
  LocalPlansSearch: 'local_plans_search',
  Login: 'login',
  SignUp: 'sign_up',
  ViewHomepage: 'view_homepage',
  SelectPromotion: 'select_promotion',
  ClickEvent: 'click_event',
  ActivePlansSelectItem: 'activeplans_plan_select_item',
  FreeTrial: 'free_trial',
  FreeTrialPurchase: 'free_trial_purchase',
  SignUpViaFreeTrial: 'sign_up_via_free_trial',
} as const;

export const APPSFLYER_EVENT = {
  Checkout: 'af_initiated_checkout',
  FirstPurchase: 'first_purchase',
  RepeatPurchase: 'af_purchase',
  AddToCart: 'af_add_to_cart',
  ViewItemList: 'af_list_view',
  Search: 'af_search',
  Login: 'af_login',
  SignUp: 'af_sign_up',
  FreeTrialPurchase: 'af_free_trial_purchase',
} as const;

export enum TRACK_EVENT {
  Checkout = 'checkout',
  Purchase = 'purchase',
}

export interface IPurchaseItem {
  id: string;
  name: string;
  brand: string;
  countryName?: string;
  itemListName?: string;
  price: number;
  quantity: number;
  retailerId: string;
  currency: string;
}
export interface CheckoutEventData {
  currency: string;
  price: number;
  isGuest: boolean;
  items: IPurchaseItem[];
}

export interface PurchaseEventData {
  retailerId: string;
  coupon: string;
  orderNumber: string;
  price: number;
  tax: number;
  isGuest: boolean;
  purchaseType: 'repeat' | 'first';
  userDestination: string;
  items: IPurchaseItem[];
  currency: string;
}

export interface AddToCartEventData {
  currency: string;
  price: number;
  items: IPurchaseItem[];
}

export interface ClickEventData {
  element_id: string;
  element_text: string;
}

export interface ActivePlansSelectItemDataList {
  items: ActivePlansSelectItemData[];
}

export interface ActivePlansSelectItemData {
  item_id: string;
  item_name: string;
  affiliation: string;
  quantity?: number;
}

export interface ViewItemListEventData {
  items: IPurchaseItem[];
}

export interface SearchEventData {
  countryName: string;
  searchClick: 'featured destination' | 'search box' | 'find my plan';
  userDestination: string;
}

export interface LoginEventData {
  method: 'Email' | 'ExternalProvider';
}

export interface SignUpEventData {
  method: 'Email' | 'ExternalProvider';
}

export interface SelectPromotionData {
  retailerId?: string;
  promotionName: string;
}

export interface FreeTrialEventData {
  trial_status: 'start' | 'skipped' | 'banner-clicked';
}

export type TrackEventDataTypes =
  | CheckoutEventData
  | PurchaseEventData
  | AddToCartEventData
  | ViewItemListEventData
  | SearchEventData
  | LoginEventData
  | SignUpEventData
  | SelectPromotionData
  | FreeTrialEventData;
