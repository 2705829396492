import 'flag-icon-css/css/flag-icon.css';

interface Props {
  countryCode: string;
}

const CountryFlag = ({ countryCode }: Props) => {
  const className = `flag-icon flag-icon-${countryCode.toLowerCase()}`;
  return <span className={className} />;
};

export default CountryFlag;
