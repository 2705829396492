import './ControllerButton.scss';

import { ComponentPropsWithoutRef } from 'react';

/**
    Helper button for inputs, pre styled for easy positioning
 */
const ControllerButton = (props: ComponentPropsWithoutRef<'button'>) => (
  <button className="c-ControllerButton" {...props}>
    {props.children}
  </button>
);

export default ControllerButton;
