import './CountrySwitch.scss';

import { Globe } from '@phosphor-icons/react';
import * as Popover from '@radix-ui/react-popover';
import { Button } from '@travelwin/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OnlineShopTerritory,
  useCountriesDomainQuery,
} from '../../../services/resources/ResourceService';
import Typeahead from '../../Typeahead';

const className = 'c-CountrySwitch';

const CountrySwitch = () => {
  const [selectedCountry, setSelectedCountry] =
    useState<OnlineShopTerritory | null>(null);
  const { t } = useTranslation();

  const { data: countriesDomain } = useCountriesDomainQuery();

  if (!countriesDomain || countriesDomain.length === 0) {
    return null;
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button type="button" className={`${className}__trigger`}>
          <Globe size={24} />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={`${className}__content`}
          sideOffset={30}
          align={'end'}
        >
          <div className={`${className}__viewport`}>
            <div className={`${className}__typeahead-label-country`}>
              {t('country_language_switch.select_store', 'Select your store')}
            </div>
            <Typeahead
              data={countriesDomain}
              onChange={(country) =>
                setSelectedCountry(country as OnlineShopTerritory)
              }
              placeholder={t(
                'country_language_switch.select_store',
                'Select your store',
              )}
              selectedOption={selectedCountry}
              countries
              emptyFilterMessage={t(
                'country_language_switch.no_stores_available',
                'No stores available',
              )}
            />
            <Button
              fluid
              onClick={() =>
                selectedCountry && window.location.assign(selectedCountry.url)
              }
            >
              {t('form.update_label', 'Update')}
            </Button>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default CountrySwitch;
