import './LanguageSwitch.scss';

import { CaretDown, CaretUp, Globe } from '@phosphor-icons/react';
import * as Select from '@radix-ui/react-select';
import { SUPPORTED_LOCALE } from '@travelwin/core';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import {
  changeLanguage,
  getCurrentLocale,
  getSupportedLanguages,
} from '../../../i18n';
import { isRemix } from '../../../utils/ssrUtils';
import {
  getPathnameWithLocale,
  getPathnameWithoutLocale,
} from '../../../utils/urlUtils';

const className = 'c-LanguageSwitch';

type LanguageSwitchVariant = 'compact' | 'full';

interface LanguageSwitchProps {
  className?: string;
  id?: string;
  container?: HTMLElement | null;
  variant?: LanguageSwitchVariant;
}

export const LanguageSwitch = ({
  className: rootClassName,
  id,
  container,
  variant = 'compact',
}: LanguageSwitchProps) => {
  const { t } = useTranslation();
  const [supportedLanguages] = useState(() => getSupportedLanguages() || []);
  // `getCurrentLocale` result may not be available on load of this file, so need to be fetched during render,
  // but because we don't want it to trigger on every render, using `useState` with state initialisation callback
  const [currentLanguage] = useState(() => getCurrentLocale());
  const location = useLocation();
  const params = useParams();

  if (supportedLanguages.length < 2) {
    return null;
  }

  const renderTranslationText = (language: SUPPORTED_LOCALE) => {
    const native = new Intl.DisplayNames(language, { type: 'language' }).of(
      language,
    );

    //this returns both texts whereas one below just returns the native if the native = currentLocale
    if (language !== currentLanguage) {
      const translated = new Intl.DisplayNames(currentLanguage, {
        type: 'language',
      }).of(language);

      return `${native} (${translated})`;
    }

    return native;
  };

  const handleValueChange = (value: string) => {
    const pathnameWithoutLocale = getPathnameWithoutLocale({
      location,
      params,
    });

    window.location.pathname = getPathnameWithLocale(
      pathnameWithoutLocale,
      value,
    );
  };

  return (
    <Select.Root
      onValueChange={(value) =>
        isRemix() ? handleValueChange(value) : changeLanguage(value)
      }
      value={currentLanguage}
    >
      <Select.Trigger
        id={id}
        className={classNames(`${className}__trigger`, rootClassName)}
        aria-label={t('language.switch', 'Language switch')}
        data-testid="button-switch-language"
      >
        {variant === 'compact' ? (
          currentLanguage.toUpperCase()
        ) : (
          <>
            <Globe size={24} />
            {renderTranslationText(currentLanguage)}
            <CaretDown size={24} weight="bold" />
          </>
        )}
      </Select.Trigger>
      <Select.Portal container={container}>
        <Select.Content
          className={`${className}__content`}
          position="popper"
          sideOffset={8}
          align={'end'}
          ref={(ref) => {
            if (!ref) return;
            ref.ontouchstart = (e) => {
              e.preventDefault();
            };
          }}
        >
          <Select.ScrollUpButton className={`${className}__scroll-button`}>
            <CaretUp weight="bold" />
          </Select.ScrollUpButton>
          <Select.Viewport className={`${className}__viewport`}>
            {supportedLanguages.map((language) => (
              <SelectItem key={language} value={language}>
                {renderTranslationText(language)}
              </SelectItem>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className={`${className}__scroll-button`}>
            <CaretDown weight="bold" />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = ({
  value,
  children,
}: {
  value: string;
  children: ReactNode;
}) => {
  return (
    <Select.Item
      className={`${className}__item`}
      value={value}
      data-testid={value}
    >
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
};
