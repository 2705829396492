const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M4.5 3.743a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5zm4.02 4.002c.006.957-.71 1.546-1.559 1.542a1.5 1.5 0 0 1-1.493-1.54 1.493 1.493 0 0 1 1.54-1.505c.88.02 1.518.665 1.513 1.503m3.76 2.76H9.758v8.56h2.664v-1.34q-.005-1.522.003-3.043c.001-.246.012-.502.075-.737.238-.877 1.027-1.444 1.907-1.304.566.088.94.416 1.097.949.097.333.14.692.145 1.039.012 1.048.01 2.095.008 3.143v1.29h2.671v-1.56q-.003-1.695.001-3.39a6 6 0 0 0-.178-1.507c-.187-.734-.574-1.341-1.203-1.78-.445-.312-.935-.513-1.482-.536q-.093-.004-.188-.01c-.28-.014-.564-.03-.831.024-.765.154-1.437.504-1.945 1.121-.06.07-.117.143-.203.25l-.02.024zm-6.598 8.562h2.65V10.51h-2.65z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default LinkedIn;
