import { ShopBrand } from '../utils';
import simlocal from './simlocal/components';
import { BrandComponentsMap } from './theme-types';

export const components: Record<ShopBrand, Partial<BrandComponentsMap>> = {
  digicel: {},
  epay: {},
  lagardere: {},
  'inland-cellular': {},
  'nex-tech-wireless': {},
  simlocal,
};
