import { getCurrentLocale } from './localeUtils';
import { formatNumber } from './numberUtils';

export const formatCurrency = (
  currency: string,
  price: string | number,
  options?: Intl.NumberFormatOptions,
) => {
  return formatNumber(typeof price === 'number' ? price : Number(price), {
    style: 'currency',
    currency,
    ...options,
  });
};

/**
 * Format number as currency without the currency symbol.
 */
export const formatCurrencyNumbers = (
  currency: string,
  price: number,
  options?: Intl.NumberFormatOptions,
) => {
  return new Intl.NumberFormat(getCurrentLocale(), {
    style: 'currency',
    currencyDisplay: 'code',
    currency,
    ...options,
  })
    .formatToParts(price)
    .filter(({ type, value }) => type !== 'currency' && value.trim() !== '')
    .map(({ value }) => value)
    .join('');
};

export const getCurrencyCode = (currency: string) => {
  const formatter = new Intl.NumberFormat(getCurrentLocale(), {
    style: 'currency',
    currency,
  });
  // Format a dummy value to get the currency symbol
  const formattedResult = formatter.formatToParts(0);

  // Find the part with "currency" type and extract the symbol
  const currencyPart = formattedResult.find((part) => part.type === 'currency');
  return currencyPart ? currencyPart.value : undefined;
};

export const getCurrencyName = (currency: string) => {
  return new Intl.DisplayNames(getCurrentLocale(), {
    type: 'currency',
  }).of(currency);
};

export const getLabel = (currency: string) =>
  `(${getCurrencyCode(currency)}) ${currency}`;
