const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 0 25 25"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.4 3.422h-8a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5v-8a5 5 0 0 0-5-5m3.25 13a3.26 3.26 0 0 1-3.25 3.25h-8a3.26 3.26 0 0 1-3.25-3.25v-8a3.26 3.26 0 0 1 3.25-3.25h8a3.26 3.26 0 0 1 3.25 3.25zm-2.5-7.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-4.75-.75a4.5 4.5 0 1 0 4.5 4.5 4.49 4.49 0 0 0-4.5-4.5m-2.75 4.5a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 0 0-5.5 0"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Instagram;
