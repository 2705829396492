import './CurrencySelector.scss';

import {
  Portal,
  Select,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@radix-ui/react-select';
import classNames from 'classnames';
import { t } from 'i18next';
import { createContext, PropsWithChildren, useContext } from 'react';

type ContextType = {
  value: string;
  onChange: (choice: string) => void;
  rootClassName: string;
};

const Context = createContext<ContextType | undefined>(undefined);
const useCurrencyComposerContext = () => {
  const context = useContext(Context);
  if (context === undefined)
    throw new Error(
      'useCurrencyComposerContext must be used within <CurrencySelection>',
    );

  return context;
};
const className = 'c-CurrencySwitch';

const CurrencySelection = ({
  children,
  rootClassName,
  onChange,
  value,
}: PropsWithChildren<ContextType>) => {
  return (
    <Context.Provider value={{ value, onChange, rootClassName }}>
      <Select value={value} onValueChange={onChange}>
        {children}
      </Select>
    </Context.Provider>
  );
};

const Trigger = ({ children }: PropsWithChildren) => {
  const { value, rootClassName } = useCurrencyComposerContext();

  return (
    <SelectTrigger
      className={classNames(`${className}__trigger`, rootClassName)}
      aria-label={t('currency.switch', 'Currency switch')}
      data-testid="switcher-currency"
      data-value={value}
    >
      {children}
    </SelectTrigger>
  );
};
CurrencySelection.Trigger = Trigger;

const Selected = ({ children }: PropsWithChildren) => (
  <SelectValue>{children}</SelectValue>
);
CurrencySelection.Selected = Selected;

const SelectedIcon = ({ children }: PropsWithChildren) => (
  <SelectIcon>{children}</SelectIcon>
);
CurrencySelection.SelectedIcon = SelectedIcon;

const Choices = ({
  children,
  container,
}: PropsWithChildren<{ container?: HTMLElement | null }>) => (
  <Portal container={container}>
    <SelectContent
      className={`${className}__content`}
      position="popper"
      sideOffset={8}
      align={'end'}
      ref={(ref) => {
        if (!ref) return;
        ref.ontouchstart = (e) => {
          e.preventDefault();
        };
      }}
    >
      <SelectViewport>{children}</SelectViewport>
    </SelectContent>
  </Portal>
);
CurrencySelection.Choices = Choices;

const Choice = ({ children, value }: PropsWithChildren<{ value: string }>) => (
  <SelectItem
    key={value}
    value={value}
    className={`${className}__item`}
    data-testid={value}
  >
    <SelectItemText>{children}</SelectItemText>
  </SelectItem>
);
CurrencySelection.Choice = Choice;

export default CurrencySelection;
