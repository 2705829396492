import { Location, Params } from 'react-router-dom';

import { isRemix } from './ssrUtils';

export const getNormalisedHostname = (hostname: string) => {
  return hostname.replace(/^www\./, '');
};

export const getCookieDomain = () => {
  if (isRemix()) {
    return undefined;
  }

  // microsites should use the main Sim Local Shop domain
  if (location.hostname.endsWith('shop.simlocal.com')) {
    return 'shop.simlocal.com';
  }

  return getNormalisedHostname(location.hostname);
};

export const getPathnameWithoutLocale = ({
  location,
  params,
}: {
  location: Location;
  params: Params;
}) => {
  const { pathname } = location;
  const { lang } = params;

  const pathnameWithoutLang = lang
    ? pathname.replace(`/${lang}`, '')
    : pathname;

  return pathnameWithoutLang === '/' || pathnameWithoutLang === ''
    ? '/'
    : pathnameWithoutLang.replace(/\/+$/, '');
};

export const getPathnameWithLocale = (
  pathname: string,
  lang: string | undefined,
) => {
  if (!lang || lang === 'en') {
    return pathname;
  }

  if (pathname === '/') {
    return `/${lang}`;
  }

  return `/${lang}${pathname.startsWith('/') ? pathname : `/${pathname}`}`;
};
