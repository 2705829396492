import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { Resource } from '../interfaces';

export const useCountryUrlResolver = () => {
  const { t } = useTranslation('countries');
  return (country: Resource) =>
    t(`countries:${country.description}`, { lng: 'en' })
      .replace(/[\s]+/g, '-')
      .toLowerCase();
};

export const useCountryStringUrlResolver = () => {
  const { t } = useTranslation('countries');
  return (country: string) =>
    t(`countries:${country}`, { lng: 'en' })
      .replace(/[\s]+/g, '-')
      .toLowerCase();
};

export const getCountryName = (countryKey: string) => {
  return i18next.t(countryKey, { lng: 'en', ns: 'countries' });
};

export const getCountryNameTranslation = (countryKey: string) => {
  return i18next.t(countryKey, { ns: 'countries' });
};
