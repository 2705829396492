import { Capacitor } from '@capacitor/core';
import { getCurrentLocale, useConfig } from '@travelwin/core';
import { useLocation } from 'react-router-dom';

import { EXTERNAL_HOMEPAGE_ENABLED_LOCALLY } from '../constants';
import { isRemix } from '../utils/ssrUtils';
import { getNormalisedHostname } from '../utils/urlUtils';

const SIMLOCAL_HOMEPAGE_URL = new URL('https://www.simlocal.com/');
const SIMLOCAL_ZENDESK_URL = new URL('https://simlocal.zendesk.com/hc');

export const useExternalHomepageConfig = () => {
  const { EXTERNAL_HOMEPAGE_ENABLED, EXTERNAL_HOMEPAGE_LOCALES = [] } =
    useConfig();
  const externalHomepageEnabledLocally =
    !isRemix() &&
    JSON.parse(
      localStorage.getItem(EXTERNAL_HOMEPAGE_ENABLED_LOCALLY) || 'false',
    );
  const location = useLocation();

  const shouldRedirectToExternalHomepage: boolean =
    !Capacitor.isNativePlatform() &&
    !isRemix() &&
    (EXTERNAL_HOMEPAGE_ENABLED || externalHomepageEnabledLocally);

  const redirectToExternalHomepage = () => {
    window.location.replace(
      getHomepageUrl(location.pathname) + location.search,
    );
  };

  const getLocalisedUrl = (url: string) => {
    const parsedUrl = new URL(url);
    const currentLocale = getCurrentLocale();
    const isSameHostname =
      getNormalisedHostname(parsedUrl.hostname) ===
      getNormalisedHostname(SIMLOCAL_HOMEPAGE_URL.hostname);

    // url localisation is only applicable for simlocal.com and locales supported there
    if (
      isSameHostname &&
      currentLocale !== 'en' &&
      EXTERNAL_HOMEPAGE_LOCALES.includes(currentLocale)
    ) {
      const newUrl = new URL(parsedUrl);
      newUrl.pathname =
        parsedUrl.pathname === '/'
          ? `/${currentLocale}`
          : `/${currentLocale}${parsedUrl.pathname}`;

      return newUrl.toString();
    }

    return url;
  };

  const getLocalizedZendeskUrl = () => {
    const url = new URL(SIMLOCAL_ZENDESK_URL);
    const currentLocale = getCurrentLocale();
    url.searchParams.set('lang', currentLocale);
    return url.toString();
  };

  const getHomepageUrl = (pathname = '') => {
    return getLocalisedUrl(new URL(pathname, SIMLOCAL_HOMEPAGE_URL).toString());
  };

  return {
    shouldRedirectToExternalHomepage,
    redirectToExternalHomepage,
    getLocalisedUrl,
    getHomepageUrl,
    getLocalizedZendeskUrl,
  };
};
