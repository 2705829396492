import { GAEventData } from '@travelwin/core/src/context/Analytics';

import {
  ActivePlansSelectItemData,
  ActivePlansSelectItemDataList,
  AddToCartEventData,
  APPSFLYER_EVENT,
  CheckoutEventData,
  ClickEventData,
  FreeTrialEventData,
  GA4_EVENT,
  IPurchaseItem,
  LoginEventData,
  PurchaseEventData,
  SearchEventData,
  SelectPromotionData,
  SignUpEventData,
  ViewItemListEventData,
} from './tracking-events';

export type GA4ConvertResult = {
  eventName: (typeof GA4_EVENT)[keyof typeof GA4_EVENT];
  generatedData: GAEventData;
};

export type AFConvertResult = {
  eventName: (typeof APPSFLYER_EVENT)[keyof typeof APPSFLYER_EVENT];
  generatedData: object;
};

const convertGA4Item = (item: IPurchaseItem) => ({
  item_id: item.id,
  item_name: item.name,
  affiliation: item.retailerId,
  item_category: item.countryName,
  item_list_name: item.itemListName,
  item_brand: item.brand,
  price: item.price,
  quantity: item.quantity,
  currency: item.currency,
});

export const convertGA4CheckoutEvent: (trackData: CheckoutEventData) => {
  eventName: (typeof GA4_EVENT)[keyof typeof GA4_EVENT];
  generatedData: GAEventData;
} = (trackData: CheckoutEventData) => ({
  eventName: GA4_EVENT.BeginCheckout,
  generatedData: {
    currency: trackData.currency,
    value: trackData.price,
    is_guest: trackData.isGuest,
    items: trackData.items.map((item) => convertGA4Item(item)),
  },
});

export const convertAFCheckoutEvent: (trackData: CheckoutEventData) => {
  eventName: (typeof APPSFLYER_EVENT)[keyof typeof APPSFLYER_EVENT];
  generatedData: object;
} = (trackData: CheckoutEventData) => ({
  eventName: APPSFLYER_EVENT.Checkout,
  generatedData: {
    af_price: trackData.price,
    af_content_id: trackData.items[0].id,
    af_content_type: trackData.items[0].countryName,
    af_currency: trackData.currency,
  },
});

export const convertGAPurchaseEvent: (
  trackData: PurchaseEventData,
) => GA4ConvertResult = (trackData: PurchaseEventData) => ({
  eventName: GA4_EVENT.Purchase,
  generatedData: {
    affiliation: trackData.retailerId,
    coupon: trackData.coupon,
    currency: trackData.currency,
    transaction_id: trackData.orderNumber,
    value: trackData.price,
    tax: trackData.tax,
    is_guest: trackData.isGuest,
    purchase_type: trackData.purchaseType,
    user_destination: trackData.userDestination,
    items: trackData.items.map((item) => convertGA4Item(item)),
  },
});

export const convertAFPurchaseEvent: (
  trackData: PurchaseEventData,
) => AFConvertResult = (trackData: PurchaseEventData) => ({
  eventName:
    trackData.purchaseType === 'first'
      ? APPSFLYER_EVENT.FirstPurchase
      : APPSFLYER_EVENT.RepeatPurchase,
  generatedData: {
    af_revenue: trackData.price,
    af_price: trackData.price,
    af_content_id: trackData.items[0].id,
    af_currency: trackData.currency,
    af_quantity: trackData.items[0].quantity,
    af_order_id: trackData.orderNumber,
  },
});

export const convertGA4AddToCartEvent: (
  trackData: AddToCartEventData,
) => GA4ConvertResult = (trackData: AddToCartEventData) => ({
  eventName: GA4_EVENT.AddToCart,
  generatedData: {
    currency: trackData.currency,
    value: trackData.price,
    items: trackData.items.map((item) => convertGA4Item(item)),
  },
});

export const convertGA4ClickEvent: (
  trackData: ClickEventData,
) => GA4ConvertResult = (trackData: ClickEventData) => ({
  eventName: GA4_EVENT.ClickEvent,
  generatedData: {
    element_id: trackData.element_id,
    element_text: trackData.element_text,
  },
});

export const convertGAActivePlansSelectItem: (
  trackData: ActivePlansSelectItemDataList,
) => GA4ConvertResult = (trackData: ActivePlansSelectItemDataList) => {
  const items = trackData.items.map((item: ActivePlansSelectItemData) => ({
    item_id: item.item_id,
    item_name: item.item_name,
    affiliation: item.affiliation,
    quantity: item.quantity,
  }));

  return {
    eventName: GA4_EVENT.ActivePlansSelectItem,
    generatedData: {
      items,
    },
  };
};

export const convertAFAddToCartEvent: (
  trackData: AddToCartEventData,
) => AFConvertResult = (trackData: AddToCartEventData) => ({
  eventName: APPSFLYER_EVENT.AddToCart,
  generatedData: {
    af_price: trackData.price,
    af_content: trackData.items[0].id,
    af_content_id: trackData.items[0].id,
    af_content_type: trackData.items[0].countryName,
    af_currency: trackData.currency,
    af_quantity: trackData.items[0].quantity,
  },
});

export const convertGA4ViewItemListEvent: (
  trackData: ViewItemListEventData,
) => GA4ConvertResult = (trackData: ViewItemListEventData) => ({
  eventName: GA4_EVENT.ViewItemList,
  generatedData: {
    items: trackData.items.map((item) => convertGA4Item(item)),
  },
});

export const convertAFViewItemListEvent: (
  trackData: ViewItemListEventData,
) => AFConvertResult = (trackData: ViewItemListEventData) => ({
  eventName: APPSFLYER_EVENT.ViewItemList,
  generatedData: {
    af_content_type: trackData.items.map((item) => item.countryName),
    af_content_list: trackData.items.map((item) => item.id),
  },
});

export const convertGA4SearchEvent: (
  trackData: SearchEventData,
) => GA4ConvertResult = (trackData: SearchEventData) => ({
  eventName:
    trackData.searchClick === 'featured destination'
      ? GA4_EVENT.LocalPlansSearch
      : GA4_EVENT.Search,
  generatedData: {
    search_term: trackData.countryName,
    search_click: trackData.searchClick,
    user_destination: trackData.userDestination,
  },
});

export const convertAFSearchEvent: (
  trackData: SearchEventData,
) => AFConvertResult = (trackData: SearchEventData) => ({
  eventName: APPSFLYER_EVENT.Search,
  generatedData: {
    af_search_string: trackData.countryName,
  },
});

export const convertGA4LoginEvent: (
  trackData: LoginEventData,
) => GA4ConvertResult = (trackData: LoginEventData) => ({
  eventName: GA4_EVENT.Login,
  generatedData: {
    method: trackData.method,
  },
});

export const convertAFLoginEvent: () => AFConvertResult = () => ({
  eventName: APPSFLYER_EVENT.Login,
  generatedData: {},
});

export const convertGA4SignUpEvent: (
  trackData: SignUpEventData,
) => GA4ConvertResult = ({ method }: SignUpEventData) => ({
  eventName: GA4_EVENT.SignUp,
  generatedData: {
    method,
  },
});

export const convertAFSignUpEvent: (
  trackData: SignUpEventData,
) => AFConvertResult = (trackData: SignUpEventData) => ({
  eventName: APPSFLYER_EVENT.SignUp,
  generatedData: {
    method: trackData.method,
  },
});

export const convertGA4ViewHomepageEvent = () => ({
  eventName: GA4_EVENT.ViewHomepage,
  generatedData: {},
});

export const convertGA4SelectPromotionEvent: (
  trackData: SelectPromotionData,
) => GA4ConvertResult = (trackData: SelectPromotionData) => ({
  eventName: GA4_EVENT.SelectPromotion,
  generatedData: {
    promotion_id: trackData.retailerId,
    promotion_name: trackData.promotionName,
  },
});

export const convertFreeTrialEvent: (
  trackData: FreeTrialEventData,
) => GA4ConvertResult = ({ trial_status }: FreeTrialEventData) => ({
  eventName: GA4_EVENT.FreeTrial,
  generatedData: {
    trial_status,
  },
});

export const convertFreeTrialPurchaseEvent: () => GA4ConvertResult = () => ({
  eventName: GA4_EVENT.FreeTrialPurchase,
  generatedData: {},
});

export const convertAppsFlyerFreeTrialPurchaseEvent: () => AFConvertResult =
  () => ({
    eventName: APPSFLYER_EVENT.FreeTrialPurchase,
    generatedData: {},
  });

export const convertGA4SignUpViaFreeTrialEvent: (
  trackData: SignUpEventData,
) => GA4ConvertResult = (trackData: SignUpEventData) => ({
  eventName: GA4_EVENT.SignUpViaFreeTrial,
  generatedData: {
    method: trackData.method,
  },
});
