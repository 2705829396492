import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient':
    'linear-gradient(287.58deg, var(--color-primary) 24.06%, #2d2e83 75.94%)',
  'color-error': '#f14643',
  'color-primary-1': '#c9e4ff',
  'color-primary-dark': '#004c99',
  'color-primary': '#0066cc',
  'color-secondary-1': '#fbe0d7',
  'color-secondary-dark': '#872b0d',
  'color-secondary': '#e94e18',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#3a7ccd',
  'color-tertiary-dark': '#1f508e',
  'font-color-highlight': 'var(--color-primary)',
  'font-color-primary': '#3a474e',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'Alexandria, Open Sans',
  'font-family-titles': 'var(--font-family-body)',

  'plan-card-background': 'linear-gradient(270deg, #1c6ab4 0%, #42b8c7 100%)',
  'plan-card-font-color': 'var(--font-color-contrast)',
  'plan-card-featured-background':
    'linear-gradient(135deg, #e94e1b 0%, #872b0d 100%)',
  'plan-card-featured-border-color': 'var(--color-secondary)',
  'plan-card-featured-header-background-color': 'transparent',
  'plan-card-featured-button-background-color': 'var(--color-secondary)',
  'plan-card-benefit-fill-color': 'var(--font-color-contrast)',
  'plan-card-benefit-border-color': 'transparent',

  'promo-banner-background':
    'linear-gradient(270deg, #1C6AB4 0%, #42B8C7 100%)',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'Alexandria',
    axes: 'wght@400;600',
  },
  // "Open Sans" is a fallback for greek glyphs
  {
    name: 'Open Sans',
    axes: 'wght@400;600',
  },
];
