import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ProductOffering, useConfig } from '@travelwin/core';
import { formatISO } from 'date-fns';
import invariant from 'tiny-invariant';

import { useCurrency } from '../../context/Currency';
import { FindMyPlanResult } from '../../pages/FindMyPlan/components/FindMyPlanStep';
import { request } from '../helpers/requests';

const PRODUCT_QUERY_KEY = 'product';

const getProductByIdQueryKey = (productId?: string, currency?: string) =>
  [PRODUCT_QUERY_KEY, { productId, currency }] as const;

const getActiveProductQueryKey = ({
  sku,
  definedCurrency,
}: {
  sku?: string;
  definedCurrency?: string;
}) => [PRODUCT_QUERY_KEY, { sku, definedCurrency }] as const;

export const setProductQueryData = (
  queryClient: QueryClient,
  product: ProductOffering,
) => {
  queryClient.setQueryData(getProductByIdQueryKey(product.id), product);
  queryClient.setQueryData(
    getActiveProductQueryKey({
      sku: product.sku,
    }),
    product,
  );
};

export const getProductOfferings = (
  country_id: string,
  travel_date: string, // format yyyy-mm-dd e.g. 2018-04-19
  currency?: string,
): Promise<ProductOffering[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      ...(currency ? { 'X-CURRENCY': currency } : {}),
    },
  };

  const id = country_id
    ? `?country_id=${country_id}&travel_date=${travel_date}`
    : '';

  const path = `ecommerce/product_offerings${id}`;

  return request(path, options);
};

const getProductOfferingsForCountries = (
  country_ids: Array<string>,
  mvno_id: string,
  travel_date: string, // format yyyy-mm-dd e.g. 2018-04-19
  currency?: string,
): Promise<ProductOffering[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      ...(currency ? { 'X-CURRENCY': currency } : {}),
    },
  };

  const country_ids_join = country_ids.join(',');
  const path = `ecommerce/product_offerings?country_ids=${country_ids_join}&mvnoId=${mvno_id}&travel_date=${travel_date}`;

  return request(path, options);
};

export const useProductsQuery = (country_id?: string) => {
  const queryClient = useQueryClient();
  const { currency } = useCurrency();
  const { PAYMENT_DEFAULT_CURRENCY } = useConfig();
  const definedCurrency = currency ?? PAYMENT_DEFAULT_CURRENCY;

  return useQuery({
    queryKey: [PRODUCT_QUERY_KEY, 'list', { country_id, definedCurrency }],
    enabled: !!country_id,
    queryFn: async () => {
      const products = await getProductOfferings(
        // if there is no country_id, query is disabled, so when this function runs it must be defined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        country_id!,
        formatISO(new Date(), { representation: 'date' }),
        definedCurrency,
      );
      products.forEach((product) => {
        setProductQueryData(queryClient, product);
      });
      return products;
    },
  });
};

export const useProductsQueryForCountries = (
  country_ids: Array<string>,
  mvno_id: string,
) => {
  const queryClient = useQueryClient();
  const { currency } = useCurrency();
  const { PAYMENT_DEFAULT_CURRENCY } = useConfig();
  const definedCurrency = currency ?? PAYMENT_DEFAULT_CURRENCY;

  return useQuery({
    queryKey: [PRODUCT_QUERY_KEY, 'list', { country_ids, definedCurrency }],
    enabled: country_ids.length > 0,
    queryFn: async () => {
      const products = await getProductOfferingsForCountries(
        country_ids,
        mvno_id,
        formatISO(new Date(), { representation: 'date' }),
        definedCurrency,
      );

      products.forEach((product) => {
        setProductQueryData(queryClient, product);
      });
      return products;
    },
  });
};

export const useProductByIdQuery = (productId?: string, currency?: string) => {
  return useQuery({
    queryKey: getProductByIdQueryKey(productId, currency),
    enabled: Boolean(productId),
    queryFn: () => {
      invariant(productId);

      const options: RequestInit = {
        method: 'GET',
        headers: {
          ...(currency ? { 'X-CURRENCY': currency } : {}),
        },
      };

      return request<ProductOffering>(
        `ecommerce/product_offerings/public/${productId}`,
        options,
      );
    },
  });
};

export const useActiveProductQuery = ({
  countryId,
  sku,
}: {
  countryId?: string;
  sku?: string;
}) => {
  const { currency } = useCurrency();
  const { PAYMENT_DEFAULT_CURRENCY } = useConfig();
  const definedCurrency = currency ?? PAYMENT_DEFAULT_CURRENCY;

  return useQuery({
    queryKey: getActiveProductQueryKey({ sku, definedCurrency }),
    enabled: Boolean(countryId) && Boolean(sku),
    queryFn: () => {
      invariant(countryId);
      invariant(sku);

      const options: RequestInit = {
        method: 'GET',
        headers: {
          ...(currency ? { 'X-CURRENCY': currency } : {}),
        },
      };

      const searchParams = new URLSearchParams({
        countryId,
        sku,
      });

      return request<ProductOffering>(
        `ecommerce/product_offerings/public/active?${searchParams}`,
        options,
      );
    },
  });
};

export const useProductQuery = ({
  productId,
  countryId,
  sku,
}: {
  productId?: string;
  countryId?: string;
  sku?: string;
}) => {
  const { currency } = useCurrency();
  const { PAYMENT_DEFAULT_CURRENCY } = useConfig();
  const definedCurrency = currency ?? PAYMENT_DEFAULT_CURRENCY;
  const productByIdQuery = useProductByIdQuery(productId, definedCurrency);
  const activeProductQuery = useActiveProductQuery({ countryId, sku });

  if (productId) {
    return productByIdQuery;
  } else if (countryId && sku) {
    return activeProductQuery;
  } else {
    throw new Error(
      'Either productId or countryId and sku need must be provided.',
    );
  }
};

export const findMyPlan = (
  data: FindMyPlanResult,
  currency?: string,
): Promise<ProductOffering[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      ...(currency ? { 'X-CURRENCY': currency } : {}),
    },
  };

  const query = new URLSearchParams(data);

  const path = `ecommerce/product_offerings?${query.toString()}`;

  return request(path, options);
};

export const useFindMyPlanMutation = (delay = 0) => {
  const queryClient = useQueryClient();
  const { currency } = useCurrency();
  const { PAYMENT_DEFAULT_CURRENCY } = useConfig();
  const definedCurrency = currency ?? PAYMENT_DEFAULT_CURRENCY;

  return useMutation({
    mutationFn: async (data: FindMyPlanResult) => {
      const findMyPlanPromise = (async () => {
        const products = await findMyPlan(data, definedCurrency);

        products.forEach((product) => {
          setProductQueryData(queryClient, product);
        });
        return products;
      })();

      const waitPromise = new Promise((r) => setTimeout(r, delay));

      const [products] = await Promise.all([findMyPlanPromise, waitPromise]);

      return products;
    },
  });
};
