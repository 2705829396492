import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import {
  GA4ContextType,
  GAContext,
  GAEventData,
  GAProvider,
  getDefaultEventData,
  noop,
  useConfig,
  useGA4,
} from '@travelwin/core';
import { getAffiliateFromStorage } from '@travelwin/core/src/utils/affiliateUtils';
import { AppsFlyer as AppsFlyerCapacitor } from 'appsflyer-capacitor-plugin';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useDeviceOS } from '../../hooks/useDeviceOS';
import { isProdApi } from '../../services/helpers/requests';
import { isMobileApp } from '../../utils/platformUtils';
import AppsFlyer from '../AppsFlyer';
import CapacitorFirebase from '../CapacitorFirebase';
import {
  AFConvertResult,
  convertAFAddToCartEvent,
  convertAFCheckoutEvent,
  convertAppsFlyerFreeTrialPurchaseEvent,
  convertAFLoginEvent,
  convertAFPurchaseEvent,
  convertAFSearchEvent,
  convertAFSignUpEvent,
  convertAFViewItemListEvent,
  convertFreeTrialEvent,
  convertFreeTrialPurchaseEvent,
  convertGA4AddToCartEvent,
  convertGA4CheckoutEvent,
  convertGA4ClickEvent,
  convertGA4LoginEvent,
  convertGA4SearchEvent,
  convertGA4SelectPromotionEvent,
  convertGA4SignUpEvent,
  convertGA4SignUpViaFreeTrialEvent,
  convertGA4ViewHomepageEvent,
  convertGA4ViewItemListEvent,
  convertGAActivePlansSelectItem,
  convertGAPurchaseEvent,
  GA4ConvertResult,
} from './Converters';
import {
  ActivePlansSelectItemDataList,
  AddToCartEventData,
  APPSFLYER_EVENT,
  CheckoutEventData,
  ClickEventData,
  FreeTrialEventData,
  GA4_EVENT,
  LoginEventData,
  PurchaseEventData,
  SearchEventData,
  SelectPromotionData,
  SignUpEventData,
  ViewItemListEventData,
} from './tracking-events';

interface AnalyticsProviderProps extends PropsWithChildren {
  isProd: boolean;
  isNative: boolean;
  isMobileWeb: boolean;
}

interface ECommerceAnalyticsContextType {
  trackPurchase: (data: PurchaseEventData) => void;
  trackClickAction: (data: ClickEventData) => void;
  trackActivePlansSelectItem: (data: ActivePlansSelectItemDataList) => void;
  trackCheckout(data: CheckoutEventData): void;
  trackAddToCart(data: AddToCartEventData): void;
  trackViewItemList: (data: ViewItemListEventData) => void;
  trackSearch: (data: SearchEventData) => void;
  trackLogin: (data: LoginEventData) => void;
  trackSignUp: (data: SignUpEventData) => void;
  trackHomepageView: () => void;
  trackSelectPromotion: (data: SelectPromotionData) => void;
  trackFreeTrial: (data: FreeTrialEventData) => void;
  trackFreeTrialPurchase: () => void;
  trackSignUpViaFreeTrial: (data: SignUpEventData) => void;
}

const ECommerceAnalyticsContext = createContext<
  ECommerceAnalyticsContextType | undefined
>(undefined);

const ECommerceAnalyticsProvider = ({
  children,
  isProd,
  isMobileWeb,
  isNative,
}: AnalyticsProviderProps) => {
  const { trackEvent: trackEventGA4 } = useGA4();

  const defaultEventData = getDefaultEventData({
    isProd,
    isNative,
    isMobileWeb,
  });

  const sendEvent = useCallback(
    (convertedDataGA?: GA4ConvertResult, convertedDataAF?: AFConvertResult) => {
      if (convertedDataGA) {
        trackEventGA4(convertedDataGA.eventName, {
          ...convertedDataGA.generatedData,
        });

        if (isProd) {
          trackEventFirebase(
            convertedDataGA.eventName,
            convertedDataGA.generatedData,
          );
        }
      }

      if (isProd) {
        if (convertedDataAF) {
          trackEventAppsFlyer(
            convertedDataAF.eventName,
            convertedDataAF.generatedData,
          );
        }
      }
    },
    [],
  );

  const trackClickAction: ECommerceAnalyticsContextType['trackClickAction'] =
    useCallback(
      (data: ClickEventData) => {
        sendEvent(convertGA4ClickEvent(data));
      },
      [sendEvent],
    );

  const trackActivePlansSelectItem: ECommerceAnalyticsContextType['trackActivePlansSelectItem'] =
    useCallback(
      (data: ActivePlansSelectItemDataList) => {
        const convertedData = convertGAActivePlansSelectItem(data);
        sendEvent(convertedData);
      },
      [sendEvent],
    );

  const trackPurchase: ECommerceAnalyticsContextType['trackPurchase'] =
    useCallback(
      (data: PurchaseEventData) => {
        sendEvent(convertGAPurchaseEvent(data), convertAFPurchaseEvent(data));
      },
      [sendEvent],
    );

  const trackCheckout: ECommerceAnalyticsContextType['trackCheckout'] =
    useCallback(
      (data: CheckoutEventData) => {
        sendEvent(convertGA4CheckoutEvent(data), convertAFCheckoutEvent(data));
      },
      [sendEvent],
    );

  const trackAddToCart: ECommerceAnalyticsContextType['trackAddToCart'] =
    useCallback(
      (data: AddToCartEventData) => {
        sendEvent(
          convertGA4AddToCartEvent(data),
          convertAFAddToCartEvent(data),
        );
      },
      [sendEvent],
    );

  const trackViewItemList: ECommerceAnalyticsContextType['trackViewItemList'] =
    useCallback(
      (data: ViewItemListEventData) => {
        sendEvent(
          convertGA4ViewItemListEvent(data),
          convertAFViewItemListEvent(data),
        );
      },
      [sendEvent],
    );

  const trackSearch: ECommerceAnalyticsContextType['trackSearch'] = useCallback(
    (data: SearchEventData) => {
      sendEvent(convertGA4SearchEvent(data), convertAFSearchEvent(data));
    },
    [sendEvent],
  );

  const trackLogin: ECommerceAnalyticsContextType['trackLogin'] = useCallback(
    (data: LoginEventData) => {
      sendEvent(convertGA4LoginEvent(data), convertAFLoginEvent());
    },
    [sendEvent],
  );

  const trackSignUp: ECommerceAnalyticsContextType['trackSignUp'] = useCallback(
    (data: SignUpEventData) => {
      sendEvent(convertGA4SignUpEvent(data), convertAFSignUpEvent(data));
    },
    [sendEvent],
  );

  const trackHomepageView: ECommerceAnalyticsContextType['trackHomepageView'] =
    useCallback(() => {
      sendEvent(convertGA4ViewHomepageEvent());
    }, [sendEvent]);

  const trackSelectPromotion: ECommerceAnalyticsContextType['trackSelectPromotion'] =
    useCallback(
      (data: SelectPromotionData) => {
        sendEvent(convertGA4SelectPromotionEvent(data));
      },
      [sendEvent],
    );

  const trackFreeTrial: ECommerceAnalyticsContextType['trackFreeTrial'] =
    useCallback(
      (data: FreeTrialEventData) => {
        sendEvent(convertFreeTrialEvent(data));
      },
      [sendEvent],
    );

  const trackFreeTrialPurchase: ECommerceAnalyticsContextType['trackFreeTrialPurchase'] =
    useCallback(() => {
      sendEvent(
        convertFreeTrialPurchaseEvent(),
        convertAppsFlyerFreeTrialPurchaseEvent(),
      );
    }, [sendEvent]);

  const trackSignUpViaFreeTrial: ECommerceAnalyticsContextType['trackSignUpViaFreeTrial'] =
    useCallback((data: SignUpEventData) => {
      sendEvent(convertGA4SignUpViaFreeTrialEvent(data));
    }, []);

  const trackEventFirebase = useCallback(
    async (
      eventName: (typeof GA4_EVENT)[keyof typeof GA4_EVENT],
      trackData: GAEventData,
    ) => {
      if (isNative) {
        try {
          const instanceId = await FirebaseAnalytics.getAppInstanceId();
          const affiliate = await getAffiliateFromStorage();

          if (instanceId) {
            await FirebaseAnalytics.logEvent({
              name: eventName,
              params: {
                ...defaultEventData,
                ...trackData,
                partner_type: affiliate ? 'b2b' : 'b2c',
                partner: affiliate,
              },
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [],
  );

  const trackEventAppsFlyer = useCallback(
    async (
      eventName: (typeof APPSFLYER_EVENT)[keyof typeof APPSFLYER_EVENT],
      trackData: object,
    ) => {
      if (isNative) {
        try {
          const uId = await AppsFlyerCapacitor.getAppsFlyerUID();

          if (uId) {
            await AppsFlyerCapacitor.logEvent({
              eventName,
              eventValue: {
                ...defaultEventData,
                ...trackData,
              },
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [],
  );

  return (
    <ECommerceAnalyticsContext.Provider
      value={useMemo(
        () => ({
          trackPurchase,
          trackClickAction,
          trackActivePlansSelectItem,
          trackCheckout,
          trackAddToCart,
          trackViewItemList,
          trackSearch,
          trackLogin,
          trackSignUp,
          trackHomepageView,
          trackSelectPromotion,
          trackFreeTrial,
          trackFreeTrialPurchase,
          trackSignUpViaFreeTrial,
        }),
        [],
      )}
    >
      {isNative && <CapacitorFirebase />}
      {isNative && <AppsFlyer />}
      {children}
    </ECommerceAnalyticsContext.Provider>
  );
};

const defaultGA4ContextValue: GA4ContextType = { trackEvent: noop };

const ECommerceAnalyticsProviderWrapper = ({ children }: PropsWithChildren) => {
  const { isMobileWeb } = useDeviceOS();

  const {
    GOOGLE_TAG_MANAGER_ID,
    CLIENT_GOOGLE_TAG_MANAGER_ID,
    GOOGLE_ANALYTICS_DISABLED,
  } = useConfig();
  const isProd = isProdApi();

  if (GOOGLE_ANALYTICS_DISABLED) {
    return <MockAnalyticsProvider>{children}</MockAnalyticsProvider>;
  }

  if (isMobileApp) {
    return (
      <GAContext.Provider value={defaultGA4ContextValue}>
        <ECommerceAnalyticsProvider
          isProd={isProd}
          isMobileWeb={isMobileWeb}
          isNative={isMobileApp}
        >
          {children}
        </ECommerceAnalyticsProvider>
      </GAContext.Provider>
    );
  }

  return (
    <GAProvider
      isProd={isProd}
      isMobileWeb={isMobileWeb}
      gtmId={GOOGLE_TAG_MANAGER_ID}
      b2bClientGtmId={CLIENT_GOOGLE_TAG_MANAGER_ID}
    >
      <ECommerceAnalyticsProvider
        isProd={isProd}
        isMobileWeb={isMobileWeb}
        isNative={isMobileApp}
      >
        {children}
      </ECommerceAnalyticsProvider>
    </GAProvider>
  );
};

const defaultECommerceAnalyticsContextValue: ECommerceAnalyticsContextType = {
  trackPurchase: noop,
  trackClickAction: noop,
  trackActivePlansSelectItem: noop,
  trackCheckout: noop,
  trackAddToCart: noop,
  trackViewItemList: noop,
  trackSearch: noop,
  trackLogin: noop,
  trackSignUp: noop,
  trackHomepageView: noop,
  trackSelectPromotion: noop,
  trackFreeTrial: noop,
  trackFreeTrialPurchase: noop,
  trackSignUpViaFreeTrial: noop,
};

const MockAnalyticsProvider = ({ children }: PropsWithChildren) => {
  return (
    <ECommerceAnalyticsContext.Provider
      value={defaultECommerceAnalyticsContextValue}
    >
      <GAContext.Provider value={defaultGA4ContextValue}>
        {children}
      </GAContext.Provider>
    </ECommerceAnalyticsContext.Provider>
  );
};

const useAnalytics = () => {
  const context = useContext(ECommerceAnalyticsContext);

  if (context === undefined) {
    throw Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
};

export {
  ECommerceAnalyticsProviderWrapper as AnalyticsProvider,
  ECommerceAnalyticsContext,
  MockAnalyticsProvider,
  useAnalytics,
};
