import { useCallback } from 'react';
import {
  NavigateFunction,
  NavigateOptions,
  To,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useNavigateBase,
  useParams,
} from 'react-router-dom';

import { getPathnameWithLocale } from '../../utils/urlUtils';

export const useNavigate = () => {
  const navigateBase = useNavigateBase();
  const params = useParams();

  const navigate: NavigateFunction = useCallback(
    (to: To | number, options?: NavigateOptions) => {
      if (typeof to === 'number') {
        return navigateBase(to);
      }

      const toWithLocale: To =
        typeof to === 'string'
          ? getPathnameWithLocale(to, params.lang)
          : {
              ...to,
              pathname:
                to.pathname !== undefined
                  ? getPathnameWithLocale(to.pathname, params.lang)
                  : undefined,
            };

      return navigateBase(toWithLocale, options);
    },
    [navigateBase, params.lang],
  );

  return navigate;
};
