import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient':
    'linear-gradient(135.05deg, #26a69a 1.74%, #004d40 100%)',
  'color-error': '#f14643',
  'color-primary-1': '#e0f2f1',
  'color-primary-dark': '#006357',
  'color-primary': '#009c8f',
  'color-secondary-1': '#fce4ec',
  'color-secondary-dark': '#960e44',
  'color-secondary': '#d5216b',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#3a7ccd',
  'color-tertiary-dark': '#1f508e',
  'color-quaternary': '#fbea00',
  'color-surface-secondary': '#f5f5f5',
  'font-color-highlight': 'var(--color-primary)',
  'font-color-primary': '#3a474e',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'Niramit',
  'font-family-titles': 'Poppins',
  'plan-card-header-background-color': 'var(--color-surface-secondary)',
  'plan-card-font-color': '#424242',
  'promo-banner-background': '#424242',
  'footer-background': 'var(--color-primary)',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'Niramit',
    axes: 'wght@400;700',
  },
  {
    name: 'Poppins',
    axes: 'wght@400;700;800',
  },
];
