import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient':
    'linear-gradient(0deg, var(--color-primary), var(--color-primary))',
  'color-error': '#f14643',
  'color-primary-1': '#b9e2b7',
  'color-primary-dark': '#235021',
  'color-primary': '#45a041',
  'color-secondary-1': '#e6d9f9',
  'color-secondary-dark': '#362550',
  'color-secondary': '#573c81',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#45a041',
  'color-tertiary-dark': '#235021',
  'font-color-highlight': 'var(--color-primary)',
  'font-color-primary': '#212121',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'Montserat, sans-serif',
  'font-family-titles': 'var(--font-family-body)',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'Montserrat',
    axes: 'wght@400;700',
  },
];
