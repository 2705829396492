import { useConfig } from '@travelwin/core';
import { FC, PropsWithChildren } from 'react';

import { ShopBrand } from '@/utils';

type Props = PropsWithChildren<
  Partial<{
    brand: ShopBrand;
    brands: ShopBrand[];
  }>
>;

/**
 * ShowTo component conditionally renders children based on brand matching
 * @param brand - Single brand to match against
 * @param brands - List of brands to match against
 * @param children - Child components to show when brand matches
 */
export const ShowTo: FC<Props> = ({ children, brand, brands }) => {
  const { WHITELABEL_BRAND } = useConfig();

  if (!children) return null;

  const isBrandMatch = (brandToCheck: ShopBrand) =>
    brandToCheck === WHITELABEL_BRAND;

  if (brand) {
    return isBrandMatch(brand) ? children : null;
  }

  if (brands?.length) {
    return brands.some(isBrandMatch) ? children : null;
  }

  return null;
};
