import * as digicel from './digicel';
import * as epay from './epay';
import * as eurostarGlobal from './eurostar-global';
import * as inlandCellular from './inland-cellular';
import * as lagardere from './lagardere';
import * as nexTechWireless from './nex-tech-wireless';
import * as simlocal from './simlocal';
import * as tripAdd from './trip-add';
import * as tripCom from './trip-com';

const themes = {
  digicel,
  epay,
  'eurostar-global': eurostarGlobal,
  'inland-cellular': inlandCellular,
  'nex-tech-wireless': nexTechWireless,
  lagardere,
  simlocal,
  'trip-add': tripAdd,
  'trip-com': tripCom,
};

export type Brand = keyof typeof themes;

export type BrandAsset = 'logo' | 'logoLight' | 'logoWhite';

export const DEFAULT_BRAND = 'simlocal' as const;

export const isDefaultBrand = (brand: Brand) => brand === DEFAULT_BRAND;

export const getBrandAssetFn =
  (brand: Brand = DEFAULT_BRAND) =>
  (assetName: BrandAsset) =>
    themes[brand][assetName];

export const getBrandVariables = (brand: Brand) => {
  return themes[brand].variables;
};

export const getBrandFontFamilies = (brand: Brand) => {
  return themes[brand].fontFamilies;
};

export const getBrandFontsUrl = (brand: Brand) => {
  const fontFamilies = getBrandFontFamilies(brand);

  const url = new URL('https://fonts.googleapis.com/css2');
  url.searchParams.append('display', 'swap');

  for (const fontFamily of fontFamilies) {
    const { name, axes } = fontFamily;

    url.searchParams.append('family', `${name}:${axes}`);
  }

  return url.toString();
};
