import { BrandAsset, getBrandAssetFn, useConfig } from '@travelwin/core';

import { defaultComponents } from '../themes/default/components';
import * as digicel from '../themes/digicel';
import * as epay from '../themes/epay';
import * as inlandCellular from '../themes/inland-cellular';
import * as lagardere from '../themes/lagardere';
import * as nexTechWireless from '../themes/nex-tech-wireless';
import * as simlocal from '../themes/simlocal';
import { components } from '../themes/theme-components';
import { BrandComponentsMap } from '../themes/theme-types';

export const shopBrands = [
  'simlocal',
  'digicel',
  'epay',
  'nex-tech-wireless',
  'inland-cellular',
  'lagardere',
] as const;

export type ShopBrand = (typeof shopBrands)[number];

type ShopBrandAssetsMap = {
  help?: string;
  login: string;
  landing?: string;
  fullWidthLanding?: string;
};

export type ShopBrandAsset = keyof ShopBrandAssetsMap;

const assets: Record<ShopBrand, ShopBrandAssetsMap> = {
  digicel,
  epay,
  'inland-cellular': inlandCellular,
  'nex-tech-wireless': nexTechWireless,
  lagardere,
  simlocal,
};

export const getBrandSpecificImage = (
  assetName: BrandAsset,
  brand: ShopBrand,
) => {
  return getBrandAssetFn(brand)(assetName);
};

export const getBrandSpecificShopImage = <T extends ShopBrandAsset>(
  assetName: T,
  brand: ShopBrand,
) => {
  return assets[brand][assetName];
};

export const useBrandSpecificShopImage = <T extends ShopBrandAsset>(
  assetName: T,
) => {
  const { WHITELABEL_BRAND } = useConfig();

  return getBrandSpecificShopImage(assetName, WHITELABEL_BRAND);
};

export const DEFAULT_SHOP_BRAND: ShopBrand = 'simlocal';

/**
 * @deprecated Use isDefaultBrand from @travelwin/core
 * @returns {boolean}
 */
export const isDefaultBrand = (brand: ShopBrand) =>
  brand === DEFAULT_SHOP_BRAND;

export type BrandComponent = keyof typeof defaultComponents;

export const getBrandSpecificComponent = <T extends BrandComponent>(
  componentName: T,
  brand: ShopBrand,
): BrandComponentsMap[T] => {
  return components[brand][componentName] ?? defaultComponents[componentName];
};

export const useBrandSpecificComponent = <T extends BrandComponent>(
  componentName: T,
) => {
  const { WHITELABEL_BRAND } = useConfig();
  return getBrandSpecificComponent(componentName, WHITELABEL_BRAND);
};
