import { BrandComponentsMap } from '../../theme-types';
import { FaqQuestion1Icon } from './FaqQuestion1Icon';
import { FaqQuestion2Icon } from './FaqQuestion2Icon';
import { FaqQuestion3Icon } from './FaqQuestion3Icon';

export const defaultComponents = {
  FaqQuestion1Icon,
  FaqQuestion2Icon,
  FaqQuestion3Icon,
} satisfies BrandComponentsMap;
