import './Layout.scss';

import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { ReactNode } from 'react';

import MobileNavFooter from '../MobileNavFooter';
import Navbar from '../Navbar';

const className = 'c-Layout';

type LayoutProps = {
  children: ReactNode;
  header?: ReactNode;
  centered?: boolean;
  showNavBar?: boolean;
};

const isNativePlatform = Capacitor.isNativePlatform();

export const Layout = ({
  children,
  header,
  centered,
  showNavBar = true,
}: LayoutProps) => {
  return (
    <>
      {Capacitor.getPlatform() === 'ios' && (
        <div className={`${className}__shell--status-bar`} />
      )}
      <div
        className={classNames(
          `${className}__shell--${Capacitor.getPlatform()}`,
          {
            [`${className}__shell--native`]: isNativePlatform,
            [`--has-nav-bar`]: showNavBar,
          },
        )}
      >
        {showNavBar && !isNativePlatform && <Navbar />}

        <div
          className={classNames(
            `${className}__frame`,
            `${className}__frame--${Capacitor.getPlatform()}`,
            {
              [`${className}__frame--has-header`]: Boolean(header),
            },
          )}
        >
          {header && (
            <div className={`${className}__frame__header`}>{header}</div>
          )}
          <div
            className={classNames(`${className}__frame--content`, {
              [`${className}__frame--content--centered`]: centered,
            })}
          >
            {children}
          </div>
        </div>

        {showNavBar && isNativePlatform && <MobileNavFooter />}
      </div>
    </>
  );
};
