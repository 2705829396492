export const ENVIRONMENTS = {
  DEV: 'DEV',
  CI: 'CI',
  TEST: 'TEST',
  UAT: 'UAT',
  STAGE: 'STAGE',
  PROD: 'PROD',
};

export const ENVIRONMENTS_URLS = {
  DEV: 'https://api.gw-ecommerce.dev.esim.travelwinnonproduction.com/',
  CI: 'https://api.gw-ecommerce.ci.esim.travelwinnonproduction.com/',
  TEST: 'https://api.gw-ecommerce.test.esim.travelwinnonproduction.com/',
  UAT: 'https://api.gw-ecommerce.uat.esim.travelwinnonproduction.com/',
  STAGE: 'https://api.gw-ecommerce.stage.esim.travelwinnonproduction.com/',
  PROD: 'https://api.gw-ecommerce.esimulatorservices.com/',
} as { [key: string]: string };
