import { Capacitor } from '@capacitor/core';
import { checkAPIVersion } from '@travelwin/core';
import { isFunction } from 'radash';

import { API_ERROR_CODES } from '../../constants';
import { isRemix } from '../../utils/ssrUtils';

/**
 * There is no point in capturing version mismatch for native app,
 * because for a native app, version mismatch is a valid use case.
 */
export const checkVersionForWebOnly = (APIVersion: string | null) => {
  if (!Capacitor.isNativePlatform() && !isRemix()) {
    checkAPIVersion(APIVersion);
  }
};

class ApiCompatibilityChecker {
  public listener: (() => void) | undefined;

  private isApiResponseVersionInvalid = (
    responseStatus?: number,
    responseBody?: unknown,
  ) => {
    const errorCode =
      typeof responseBody === 'object' &&
      responseBody !== null &&
      'error_code' in responseBody
        ? responseBody.error_code
        : null;

    return (
      responseStatus === 400 &&
      errorCode === API_ERROR_CODES.CLIENT_NOT_SUPPORTED
    );
  };

  public check(responseStatus?: number, responseBody?: unknown) {
    if (isFunction(this.listener)) {
      if (this.isApiResponseVersionInvalid(responseStatus, responseBody)) {
        this.listener();
      }
    }
  }
}

export const apiCompatibilityChecker = new ApiCompatibilityChecker();

export class ApiCompatibilityError extends Error {
  constructor() {
    super('Application version is incompatible with the API');
    this.name = 'ApiCompatibilityError';
  }
}
