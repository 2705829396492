import { isDefaultBrand, useConfig } from '@travelwin/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isRemix } from '../utils/ssrUtils';
import { useExternalHomepageConfig } from './useExternalHomepageConfig';

export type NavItem = {
  id: string;
  label: string;
  to?: string;
  ecommerceRoute?: boolean;
  items?: Array<NavItem>;
  visible: boolean;
  'data-testid'?: string;
};

export const useNavbarItems = () => {
  const { WHITELABEL_BRAND } = useConfig();
  const { t } = useTranslation();
  const { getHomepageUrl, getLocalizedZendeskUrl } =
    useExternalHomepageConfig();

  const navbarItems = useMemo(
    () => [
      {
        id: '200',
        label: t('navbar.about_us', 'About Us'),
        visible: isDefaultBrand(WHITELABEL_BRAND),
        items: [
          {
            id: '201',
            label: t('navbar.about_sim_local', 'About Sim Local'),
            to: getHomepageUrl('/about-us/about-sim-local'),
            visible: true,
          },
          {
            id: '202',
            label: t('navbar.careers', 'Careers'),
            to: getHomepageUrl('/about-us/careers'),
            visible: true,
          },
          {
            id: '203',
            label: t('navbar.partners', 'Partners'),
            to: getHomepageUrl('/partners'),
            visible: true,
          },
          {
            id: '204',
            label: t('navbar.news', 'News'),
            to: isRemix() ? '/news' : getHomepageUrl('/news'),
            visible: true,
            ecommerceRoute: isRemix(),
          },
        ],
      },
      {
        id: '300',
        label: t('navbar.help', 'Help'),
        visible: isDefaultBrand(WHITELABEL_BRAND),
        'data-testid': 'link-help',
        items: [
          {
            id: '301',
            label: t('navbar.faqs', 'Help Center'),
            to: getLocalizedZendeskUrl(),
            visible: true,
          },
          {
            id: '302',
            label: t('navbar.store_location', 'Find a Store'),
            to: getHomepageUrl('/store-locations'),
            visible: true,
          },
          {
            id: '303',
            label: t('navbar.esim_travel', 'eSIM Travel'),
            to: getHomepageUrl('/travel-esim'),
            visible: true,
          },
          {
            id: '304',
            label: t('navbar.activation_guide', 'eSIM Activation Guides'),
            to: getHomepageUrl('/support/esim-activation-guide'),
            visible: true,
          },
          {
            id: '305',
            label: t('navbar.compatible_handsets', 'eSIM Compatible Phones'),
            to: getHomepageUrl('/support/compatible-handsets'),
            visible: true,
          },
          {
            id: '306',
            label: t('navbar.phone_unlocking', 'Phone Unlocking'),
            to: getHomepageUrl('/support/phone-unlocking'),
            visible: true,
          },
          {
            id: '307',
            label: t('navbar.contact_us', 'Customer Support'),
            to: getHomepageUrl('/support/contact-us'),
            visible: true,
          },
        ],
      },
      {
        id: '1',
        label: t('navbar.my_esim', 'My eSIM'),
        to: '/orders',
        ecommerceRoute: true,
        visible: true,
        'data-testid': 'link-my-eSIM',
      },
      {
        id: '2',
        label: t('navbar.my_account', 'My Account'),
        to: '/my-account',
        ecommerceRoute: true,
        visible: true,
        'data-testid': 'link-account',
      },
    ],
    [t, WHITELABEL_BRAND, getHomepageUrl, getLocalizedZendeskUrl],
  );

  return { navbarItems };
};
