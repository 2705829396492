export const mockSimSubscriptions = {
  activeSubscriptionInfoCount: 2,
  activeSubscriptionInfoCountMax: 2,
  callState: 0,
  carrierName: 'CHIPPIE',
  countryCode: 'ai',
  dataActivity: 4,
  deviceSoftwareVersion: '20',
  isNetworkRoaming: false,
  mcc: '362',
  mnc: '91',
  networkType: 13,
  phoneCount: 2,
  phoneNumber: '',
  phoneType: 1,
  simState: 5,
  subscriptions: [
    {
      carrierName: 'TESTCARRIER',
      countryCode: 'ai',
      displayName: 'CHIPPIE',
      iccid: '89599919300312375347',
      isActive: true,
      isDataRoaming: false,
      isEmbedded: false,
      isNetworkRoaming: false,
      mcc: 362,
      mnc: 91,
      phoneNumber: '',
      simSlotIndex: 0,
      subscriptionId: 3,
    },
    {
      carrierName: 'DIGICEL- KEDAKAS — Truphone',
      countryCode: 'nl',
      displayName: 'Truphone 3',
      iccid: '8944478600000268541',
      isActive: false,
      isDataRoaming: false,
      isEmbedded: true,
      isNetworkRoaming: false,
      mcc: 204,
      mnc: 4,
      phoneNumber: '',
      simSlotIndex: 1,
      subscriptionId: 8,
    },
  ],
};
