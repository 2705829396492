import './Button.scss';

import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import Icon, { IconName } from '../icons/Icon';
import Spinner from '../Spinner';

type ButtonSize = 'small' | 'medium';
type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text';
type IconPosition = 'left' | 'right';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  size?: ButtonSize;
  variant?: ButtonVariant;
  ref?: React.Ref<HTMLButtonElement>;
  /** Overrides the max width and sets it to 100% */
  fluid?: boolean;
  wide?: boolean;
  isLoading?: boolean;
  /** @deprecated Use `icon` prop instead */
  iconName?: IconName;
  icon?: ReactNode;
  iconPosition?: IconPosition;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loaderSize?: number;
  loaderColour?: 'white' | 'black';
}

const className = 'c-Button';

const Button = ({
  ref,
  disabled,
  variant = 'primary',
  fluid,
  wide,
  iconName,
  icon,
  iconPosition = 'left',
  children,
  isLoading,
  size = 'medium',
  loaderSize = 16,
  loaderColour = 'white',
  onClick,
  ...props
}: ButtonProps) => {
  const activeClassNames = classNames(className, `${className}--${size}`, {
    [`${className}--${variant}`]: variant,
    [`${className}--disabled`]: disabled,
    [`${className}--fluid`]: fluid,
    [`${className}--wide`]: wide,
    [`${className}--w-icon ${className}--icon-${iconPosition}`]:
      iconName || icon,
    [`${className}--icon-only`]: icon && !children,
  });

  return (
    <button
      className={activeClassNames}
      onClick={onClick}
      ref={ref}
      disabled={isLoading || disabled}
      type={props.type || 'button'}
      {...props}
    >
      {isLoading ? (
        <Spinner size={loaderSize} color={loaderColour} />
      ) : (
        <div>
          {!!iconName && <Icon name={iconName} />}
          {icon ? icon : null}
          {children}
        </div>
      )}
    </button>
  );
};

export default Button;
