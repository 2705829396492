import { FontFamily, ThemeVariables } from '../theme-types';

export const variables: ThemeVariables = {
  'background-gradient':
    'linear-gradient(135.05deg, var(--color-primary) 1.74%, var(--color-primary-dark) 100%)',
  'color-error': '#f14643',
  'color-primary': '#01509d',
  'color-primary-1': '#aaccf6',
  'color-primary-dark': '#013465',
  'color-secondary-1': '#d9e9ff',
  'color-secondary': '#01509d',
  'color-secondary-dark': '#013465',
  'color-tertiary-1': '#f2f7fd',
  'color-tertiary': '#3a7ccd',
  'color-tertiary-dark': '#1f508e',
  'font-color-highlight': 'var(--color-primary)',
  'font-color-primary': '#212121',
  'font-color-secondary': '#6e7881',
  'font-family-body': 'PT Sans',
  'font-family-titles': 'Open Sans',
};

export const fontFamilies: FontFamily[] = [
  {
    name: 'PT Sans',
    axes: 'wght@400;700',
  },
  {
    name: 'Open Sans',
    axes: 'wdth,wght@75,600;75,700',
  },
];
