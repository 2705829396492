import i18n from 'i18next';

import { SUPPORTED_LOCALE } from '../supportedLocales';

export const getCurrentLocale = () => {
  // some apps use brand-specific locales e.g. en-digicel
  return i18n.language?.substring(0, 2) as SUPPORTED_LOCALE;
};

// translation keys with "+brand" suffix overwrite the default translations
export const getFlavoredTranslations = (
  translations: Record<string, string>,
  brand: string,
) => {
  const entries: Array<[string, string]> = [];

  for (const [key, value] of Object.entries(translations)) {
    if (!key.includes('+')) {
      entries.push([key, value]);
    } else if (key.endsWith(`+${brand}`)) {
      entries.push([key.substring(0, key.indexOf(`+${brand}`)), value]);
    }
  }

  return Object.fromEntries(entries);
};
