import {
  array,
  boolean,
  mixed,
  number,
  object,
  ObjectSchema,
  string,
} from 'yup';

import { EcommerceConfig } from '../../interfaces';
import { ShopBrand, shopBrands } from '../../utils';

export const ecommerceConfigSchema: ObjectSchema<EcommerceConfig> = object({
  ACTIVATION_TIMEOUT_TOPUP_MS: number().default(30000),
  ACTIVATION_SUCCESS_TOPUP_MIN_TIME_MS: number().default(1000),

  APP_RATING_ENABLED: boolean().default(false),
  APPLE_STORE_APP_URL: string(),
  GOOGLE_PLAY_APP_URL: string(),

  AWIN_ADVERTISER_ID: string(),
  AWIN_ADVERTISER_IDS: string(),
  AWIN_SALES_CHANNEL: string().default('aw'),
  AWIN_TEST: boolean().default(true),

  EUICC_INTERVAL_TIMEOUT_MS: number().default(1000),
  EUICC_MAX_TIMEOUT_MS: number().default(60000),

  ACTIVATION_MIN_TIME_ESIM_MS: number().default(15000),
  ACTIVATION_TIMEOUT_ESIM_MS: number().default(30000),
  ACTIVATION_SUCCESS_ESIM_MIN_TIME_MS: number().default(1000),

  COOKIEBOT_DOMAIN_GROUP_ID: string(),

  COGNITO_REGION: string().required(),
  COGNITO_USER_POOL_ID: string().required(),
  COGNITO_USER_POOL_WEB_CLIENT_ID: string().required(),
  COGNITO_OAUTH_DOMAIN: string(),

  BRAND_NAME: string().required(),
  WHITELABEL_BRAND: mixed<ShopBrand>().oneOf(shopBrands).required(),

  PRIVACY_POLICY_URL: string().required(),
  TERMS_AND_CONDITIONS_URL: string().required(),
  TERMS_AND_CONDITIONS_MVNO_URL: string().required(),

  CONTACT_US_EMAIL_ADDRESS: string().required(),
  CONTACT_US_PHONE_NUMBER: string().required(),

  STRIPE_PUBLISHABLE_API_KEY: string(),
  SHOW_STRIPE_CREDIT_CARD: boolean().default(true),

  SIMULATE_LPA: boolean().default(false),
  LPA_DEBUGGING_ENABLED: boolean().default(false),

  GOOGLE_ANALYTICS: string(),
  GOOGLE_ANALYTICS_DISABLED: boolean().default(true),
  GOOGLE_TAG_MANAGER_ID: string(),
  CLIENT_GOOGLE_TAG_MANAGER_ID: string(),

  META_PIXEL_ID: string(),

  IS_EKYC_ENABLED: boolean().default(false),

  EXTERNAL_HOMEPAGE_ENABLED: boolean().default(false),
  EXTERNAL_HOMEPAGE_LOCALES: array()
    .of(string().required())
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/) : [];
    }),

  PRE_POPULATE_DESTINATION: boolean().default(false),

  GUEST_CHECKOUT_ENABLED: boolean().default(false),

  SHOW_EPAY_VOUCHER: boolean().default(false),

  ORDER_CANCEL_ALLOWED: boolean().default(false),

  MARKETING_ENABLED: boolean().default(false),

  USER_ACTIVITY_TIMEOUT: number().default(30),

  APPSFLYER_DEVKEY: string(),

  PAYMENT_DEFAULT_CURRENCY: string(),

  PAYMENT_SUPPORTED_CURRENCIES: array()
    .of(string().required())
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/) : [];
    }),

  ESIM_IOS_UNIVERSAL_LINK_ENABLED: boolean().default(false),

  FIND_MY_PLAN_ENABLED: boolean().default(false),

  ZENDESK_CHAT_ENABLED: boolean().default(false),
  ZENDESK_API_KEY: string(),

  FREE_TRIAL_OFFER_ENABLED: boolean().default(false),

  ADDONS_ENABLED: boolean().default(true),

  TIKTOK_PIXEL_CODE: string(),

  WHATSAPP_CONTACT_NUMBER: string(),
}).required();
