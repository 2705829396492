import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FreeTrialData, OrderStatus, OrderType } from '@travelwin/core';
import { useCallback } from 'react';

import { DeepPartial } from '../../@types/types';
import { OrderHistoryType } from '../../pages/EsimDetails/components/OrderHistory/OrderHistory';
import { request } from '../helpers/authorizedRequests';
import { setProductQueryData } from '../product/ProductOfferingService';

export type OrderItemActiveOffer = {
  end_time?: string | null;
  initial_data_quantity?: number | null;
  offer_description: string;
  remaining_data_quantity: number | null;
  start_time: string | null;
};

export interface OrderItemDataStatus {
  active_offers: OrderItemActiveOffer[] | [];
  iccid: string;
  matching_id: string;
  profile_status: string | null;
}

const ordersServiceUri = 'ecommerce/orders';

export const ORDER_QUERY_KEY = 'order';
const ORDER_HISTORY_QUERY_KEY = 'order_history';
const OPERATOR_FEATURES_QUERY_KEY = 'operator_features';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getOrders = (
  orderStatuses?: `${OrderStatus}`[],
): Promise<OrderType[]> => {
  const options = {
    method: 'GET',
  };

  const queryString = orderStatuses
    ? `?${new URLSearchParams(
        orderStatuses.map((status) => ['orderStatuses', status]),
      ).toString()}`
    : '';

  return request(`${ordersServiceUri}${queryString}`, options);
};

export const getEsimStatusList = (
  orderStatuses?: `${OrderStatus}`[],
): Promise<OrderItemDataStatus[]> => {
  const options = {
    method: 'GET',
  };

  const queryString = orderStatuses
    ? `?${new URLSearchParams(
        orderStatuses.map((status) => ['orderStatuses', status]),
      ).toString()}`
    : '';

  return request(`ecommerce/esim/statuses${queryString}`, options);
};

export const getOrder = (
  orderId: string,
  activationKey?: string,
): Promise<OrderType> => {
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      ...(activationKey && { 'X-ACTIVATION-KEY': activationKey }),
    },
  };

  const path = `${ordersServiceUri}/${orderId}`;

  return request(path, options);
};

export const getOrderItemLpaPermissions = (itemId: string) => {
  const options = {
    method: 'GET',
    headers,
  };

  const path = `ecommerce/esim/${itemId}/direct_activation`;

  return request(path, options);
};

export const getOrderItemOperatorFeatures = (orderItemId: string) => {
  const options = {
    method: 'GET',
    headers,
  };

  const path = `ecommerce/esim/${orderItemId}/operator_features`;

  return request(path, options);
};

export const useOrderItemOperatorFeaturesQuery = ({
  orderItemId,
  enabled,
}: {
  orderItemId?: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [OPERATOR_FEATURES_QUERY_KEY, orderItemId],
    enabled,
    queryFn: async () => {
      // query will be disabled and queryFn won't be called if orderId is not defined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await getOrderItemOperatorFeatures(orderItemId!);
    },
  });
};

export const getOrderItemStatus = async (
  orderItemId: string,
): Promise<OrderItemDataStatus | Record<string, never>> => {
  const options = {
    method: 'GET',
    headers,
  };
  const path = `ecommerce/esim/${orderItemId}/status`;

  return request(path, options);
};

export const useOrderItemStatusQuery = ({
  orderItemId,
  enabled,
}: {
  orderItemId?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [ORDER_QUERY_KEY, orderItemId],
    enabled,
    queryFn: async () => {
      // query will be disabled and queryFn won't be called if orderId is not defined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await getOrderItemStatus(orderItemId!);
    },
  });
};

export const postOrder = (
  order: DeepPartial<OrderType>,
): Promise<OrderType> => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(order),
  };

  return request(ordersServiceUri, options);
};

export const updateOrder = (order: OrderType): Promise<OrderType> => {
  // Note: Workaround for truphone activation.
  // Uncomment when making truphone purchase or sim will not activate
  // TODO: Get issue fixed more permanently https://simlocal.atlassian.net/browse/APPESHOP-291
  // if (order.country === null) {
  //   order.country = {id: "113", alias: "113", description: null, display_order: null}
  // }
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(order),
  };

  return request(ordersServiceUri, options);
};

export const refundOrder = (orderId: string): Promise<OrderType> => {
  const options = {
    method: 'PUT',
    headers,
  };

  const path = `${ordersServiceUri}/${orderId}/cancel`;

  return request(path, options);
};

export const completeOrder = (
  order: OrderType,
  additionalHeaders?: Record<string, string | undefined>,
): Promise<OrderType> => {
  const options = {
    method: 'PUT',
    headers: {
      ...headers,
      ...additionalHeaders,
    },
    body: JSON.stringify(order),
  };

  const path = `${ordersServiceUri}/${order.id}/complete`;

  return request(path, options);
};

export const getOrderCount = (email: string): Promise<number> => {
  const options = {
    method: 'GET',
  };

  const path = `${ordersServiceUri}/count?email=${encodeURIComponent(email)}`;

  return request(path, options);
};

export const activateOrderItem = (
  orderItemId: string,
  activationKey?: string,
) => {
  const headers: Record<string, string> = {};

  if (activationKey) {
    headers['X-ACTIVATION-KEY'] = activationKey;
  }

  const options: RequestInit = {
    method: 'POST',
    headers,
  };

  const path = `ecommerce/esim/${orderItemId}/activate`;
  return request(path, options);
};

export const getOrderStatus = (orderId: string): Promise<string> => {
  const options = {
    method: 'GET',
  };

  const path = `${ordersServiceUri}/${orderId}/status`;

  return request(path, options);
};

export const renewBundleService = (orderItemId: string) => {
  const options = {
    method: 'POST',
  };

  const path = `ecommerce/esim/${orderItemId}/topup`;

  return request(path, options);
};

export const applyAddonService = (
  orderItemId: string,
  productOfferingId: string,
) => {
  const options = {
    method: 'POST',
  };

  const path = `ecommerce/esim/${orderItemId}/topup?productOfferingId=${productOfferingId}`;

  return request(path, options);
};

export const affiliateOrderService = (orderNumber?: string) => {
  const options = {
    method: 'POST',
    headers,
  };

  const queryParams = orderNumber ? `?order_number=${orderNumber}` : '';
  const path = `${ordersServiceUri}/affiliate${queryParams}`;

  return request(path, options);
};

export const getOrderHistory = (
  ssn: string,
): Promise<Array<OrderHistoryType>> => {
  const options = {
    method: 'GET',
  };

  const path = `${ordersServiceUri}/history?ssn=${ssn}`;

  return request(path, options);
};

export const useOrderHistoryQuery = ({
  ssn,
  enabled,
}: {
  ssn?: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ORDER_HISTORY_QUERY_KEY, ssn],
    enabled,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getOrderHistory(ssn!),
  });
};

export const useOrderData = (orderId: string) => {
  const queryClient = useQueryClient();
  return queryClient.getQueryData([ORDER_QUERY_KEY, orderId]);
};

export const useOrderQuery = ({
  orderId,
  enabled = Boolean(orderId),
}: {
  orderId?: string;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ORDER_QUERY_KEY, orderId],
    enabled,
    queryFn: async () => {
      // query will be disabled and queryFn won't be called if orderId is not defined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const order = await getOrder(orderId!);
      setProductQueryData(queryClient, order.items[0].product_offering);

      return order;
    },
  });
};

export const startFreeTrial = (): Promise<FreeTrialData> => {
  const options = {
    method: 'POST',
    headers,
  };

  const path = `ecommerce/start_free_trial`;
  return request(path, options);
};

/**
 * Usually we don't want to set query data outside react-query query/mutation.
 * Here we do it that way, because order handling is not entirely done using react-query.
 *
 * Once all order fetch/create/update operations are updated to use react-query,
 * this functionality should be enclosed in these operations.
 * @deprecated
 */
export const useOrderQueryDataSetter = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (order: OrderType) => {
      queryClient.setQueryData([ORDER_QUERY_KEY, order.id], order);
    },
    [queryClient],
  );
};
