import { Browser } from '@capacitor/browser';

export const openUrl = (url: string) => {
  if (url.startsWith('com.simlocal.esim.travel')) {
    // on ios the in-app browser has to be closed manually
    Browser.close();

    let parsedUrl = new URL(url);

    // (TF-597) Fix for Android: url.pathname will return origin/pathname if we don't do this
    if (parsedUrl.pathname.length > 1) {
      const urlWithoutCustomProtocol = url.replace(
        'com.simlocal.esim.travel',
        'https',
      );

      parsedUrl = new URL(urlWithoutCustomProtocol);
    }

    const newUrl = new URL(window.location.origin);
    newUrl.pathname = parsedUrl.pathname;
    newUrl.search = parsedUrl.search;

    // pass OAuth redirection params (e.g. `?code`) to a new location
    // that can be picked up by and parsed by @aws-amplify/auth
    window.location.href = newUrl.toString();
  }
};
