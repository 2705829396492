import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { noop } from '@travelwin/core';
import Cookies from 'js-cookie';
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getCookieDomain } from '../../utils/urlUtils';

const CURRENCY_STORAGE_KEY = 'currency';

export const CurrencyContext = createContext<{
  currency: string | undefined;
  setCurrency: (newValue: string) => void;
}>({
  currency: undefined,
  setCurrency: noop,
});

const CurrencyCookies = Cookies.withAttributes({
  expires: 365,
  domain: getCookieDomain(),
});

export const CurrencyProvider = ({
  children,
  ssrCurrency,
}: PropsWithChildren<{ ssrCurrency?: string }>) => {
  const [currency, setCurrency] = useState<string | undefined>(
    ssrCurrency ?? undefined,
  );

  useEffect(() => {
    const initCurrency = async () => {
      if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key: CURRENCY_STORAGE_KEY });
        setCurrency(value ?? undefined);
      } else {
        setCurrency(CurrencyCookies.get(CURRENCY_STORAGE_KEY));
      }
    };

    initCurrency();
  }, []);

  useEffect(() => {
    const saveCurrency = async (newValue: string) => {
      if (Capacitor.isNativePlatform()) {
        await Preferences.set({ key: CURRENCY_STORAGE_KEY, value: newValue });
      } else {
        CurrencyCookies.set(CURRENCY_STORAGE_KEY, newValue);
      }
    };

    if (currency) {
      saveCurrency(currency);
    }
  }, [currency]);

  const value = useMemo(
    () => ({
      currency,
      setCurrency,
    }),
    [currency],
  );

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
