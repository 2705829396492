import { useQuery } from '@tanstack/react-query';
import { OrderStatus, OrderType } from '@travelwin/core';
import { guard } from 'radash';
import { useContext } from 'react';

import { EuiccManagerContext } from '../context/EuiccManager';
import { getEsimsFromOrders } from '../pages/Orders/util';
import {
  getEsimStatusList,
  getOrders,
  OrderItemDataStatus,
} from '../services/order/OrderService';

const useOrdersQuery = (
  enabled: boolean,
  orderStatuses?: `${OrderStatus}`[],
) => {
  const { refreshSimSubscriptions } = useContext(EuiccManagerContext);

  return useQuery({
    queryKey: ['orders', orderStatuses],
    enabled,
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const esimStatusList: OrderItemDataStatus[] =
        (await guard(() => getEsimStatusList(orderStatuses))) ?? [];
      const orders: OrderType[] = await getOrders(orderStatuses);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subscriptions: { [key: string]: any } =
        await refreshSimSubscriptions();
      const fetchedESimList = getEsimsFromOrders(orders);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eSimList: OrderType[] = [];

      fetchedESimList.forEach((order) => {
        const iccid = order.items[0]?.ssn;
        const orderItems = order.items;
        const esimStatusForCurrentOrder = esimStatusList.find(
          (orderStatusItem) =>
            String(orderStatusItem.iccid) === String(orderItems[0]?.ssn),
        );
        const realExpiryDate =
          esimStatusForCurrentOrder?.active_offers?.[0]?.end_time;
        if (realExpiryDate) {
          orderItems[0].expiry_date = realExpiryDate;
        }
        const realInitialData =
          esimStatusForCurrentOrder?.active_offers?.[0]?.initial_data_quantity;
        if (realInitialData) {
          orderItems[0].initial_data = realInitialData;
        }
        const realRemainingData =
          esimStatusForCurrentOrder?.active_offers?.[0]
            ?.remaining_data_quantity;
        if (realRemainingData) {
          orderItems[0].remaining_data = realRemainingData;
        }
        if (iccid && subscriptions[iccid]) {
          eSimList.push({
            ...order,
            items: [
              {
                ...subscriptions[iccid],
                ...orderItems[0],
              },
            ],
          });
        } else {
          eSimList.push(order);
        }
      });

      return eSimList;
    },
  });
};

export default useOrdersQuery;
