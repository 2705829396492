export enum ACTION_VIA_SOURCE {
  FREE_TRIAL = 'Free Trial',
  FIND_MY_PLAN = 'Find My Plan',
}

export const FromType: { [key: string]: string } = {
  find_my_plan: ACTION_VIA_SOURCE.FIND_MY_PLAN,
};

export const getFromText = (text: string | null) => {
  return text ? FromType[text] || null : null;
};

export const ANALYTICS_EVENT_TYPES = {
  ConfirmDeviceUnlocked: 'confirm_device_unlocked',
  ConfirmOrderSummary: 'confirm_order_summary',
  ConfirmTruphoneTerms: 'confirm_truphone_terms',
  PaymentMethod: 'payment_method',
  PurchaseError: 'purchase_error',
  PurchaseBundleTimeout: 'purchase_bundle_timeout',
  EsimActivateClicked: 'esim_activate_clicked',
  EsimDetailsClicked: 'esim_details_clicked',
  AccountUpdateDetails: 'account_update_details',
  AccountChangePassword: 'account_change_password',
  Logout: 'logout',
  ForgotPassword: 'forgot_password',
  LoginNotVerified: 'login_not_verified',
  LoginInvalidCreds: 'login_invalid_creds',
  SignUpError: 'sign_up_error',
  SignUpCodeValid: 'sign_up_code_valid',
  SignUpCodeError: 'sign_up_code_error',
  SignUpTab: 'sign_up_tab',
  ReturnOrderSuccess: 'return_order_success',
  PlanSelectionPageBuyClicked: 'plan_selection_page_buy_clicked',
  PlanSelectionPageDetailsClicked: 'plan_selection_page_details_clicked',
  PlanDetailsPageBuyClicked: 'plan_details_page_buy_clicked',
  OrderSummaryPageBuyClicked: 'order_summary_page_buy_clicked',
  CheckoutPageBuyClicked: 'checkout_page_buy_clicked',
  CheckoutPagePaymentError: 'checkout_page_payment_error',
  CheckoutPagePaymentSuccessful: 'checkout_page_payment_successful',
  DeleteAccountClicked: 'delete_account_clicked',
  DeleteAccountSuccess: 'delete_account_success',
  EsimDirectDownloadClicked: 'esim_direct_download_clicked',
  EsimDetailsPageEsimRenewBundleClicked:
    'esim_details_page_esim_renew_bundle_clicked',
  DirectDownloadAppstoreSelected: 'direct_download_appstore_selected',
  DirectDownloadPlaystoreSelectedPlay: 'direct_download_playstore_selected',
  InstallPlanClicked: 'install_plan_clicked',
  OrdersPageEsimRenewBundleClicked: 'orders_page_esim_renew_bundle_clicked',
  ApplyRenewBundlePageBuyClicked: 'apply_renew_bundle_page_buy_clicked',
  EsimDetailsPageBuyAddonsClicked: 'esim_details_page_view_addons_clicked',
  AddOnsBuyClicked: 'add_ons_buy_clicked',
  ReturnOrderClicked: 'return_order_clicked',
} as const;
