import { Capacitor } from '@capacitor/core';
import {
  API_VERSION_HEADER,
  getCurrentAppVersion,
  XAppOrigin,
} from '@travelwin/core';
import { v4 as uuidv4 } from 'uuid';

import { DEV_API_ENVIRONMENT, ENVIRONMENTS_URLS } from '../../constants';
import { getCurrentFlavoredLocale } from '../../i18n';
import { getUniteBaseUrl, isRemix } from '../../utils/ssrUtils';
import { HttpError } from './authorizedRequests';
import {
  apiCompatibilityChecker,
  checkVersionForWebOnly,
} from './eCommerceVersionCheck';

let LOCAL_STORAGE_ENV_CACHE = '';

const cacheApiEnvironment = (apiEnvironment: string) => {
  LOCAL_STORAGE_ENV_CACHE = apiEnvironment;
};

export const getAppOrigin = () => {
  switch (Capacitor.getPlatform()) {
    case 'ios':
      return XAppOrigin.iOS;
    case 'android':
      return XAppOrigin.Android;
    default:
      return XAppOrigin.Web;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const request = async <T = any>(path: string, options?: RequestInit) => {
  const basePath = getApiUrl() || '/';

  const clientTransactionId = uuidv4();
  const optionsWithHeader = {
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(typeof window === 'undefined'
        ? { Origin: process.env.REACT_APP_CONFIG_ORIGIN }
        : {}),
      'X-LOCALE': getCurrentFlavoredLocale(),
      'X-APP-ORIGIN': getAppOrigin(),
      'X-APP-VERSION': getCurrentAppVersion(),
      'X-DAPX-CLIENT-TRANSACTION-ID': clientTransactionId,
      ...options?.headers,
    },
  };

  const endpoint = `${basePath}${path}`;
  const response = await fetch(endpoint, optionsWithHeader);

  checkVersionForWebOnly(response.headers.get(API_VERSION_HEADER));

  let body;
  try {
    body = await response.json();
  } catch (e) {
    /* empty */
  }

  apiCompatibilityChecker.check(response.status, body);

  if (response.ok) {
    return body as T;
  }
  throw new HttpError(
    path,
    options?.method ?? 'GET',
    response.status,
    body,
    clientTransactionId,
    body?.error,
  );
};

export const getApiUrl = () => {
  if (isRemix()) {
    return getUniteBaseUrl();
  }

  updateApiEnvironment();
  const cachedApiEnvironment = localStorage.getItem(DEV_API_ENVIRONMENT);

  if (cachedApiEnvironment && ENVIRONMENTS_URLS[cachedApiEnvironment]) {
    cacheApiEnvironment(cachedApiEnvironment);
    return ENVIRONMENTS_URLS[cachedApiEnvironment];
  } else {
    return import.meta.env.REACT_APP_API_ENDPOINT_ECOMMERCE;
  }
};

// updates local storage api env with cached value because
// on iOS local storage is cleared a few seconds after reload if cookie consent is not accepted
export const updateApiEnvironment = () => {
  if (LOCAL_STORAGE_ENV_CACHE && !localStorage.getItem(DEV_API_ENVIRONMENT)) {
    localStorage.setItem(DEV_API_ENVIRONMENT, LOCAL_STORAGE_ENV_CACHE);
  }
};

export const isProdApi = () => {
  return ENVIRONMENTS_URLS.PROD === getApiUrl();
};
