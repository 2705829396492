import Auth from '@aws-amplify/auth';
import { Capacitor, Plugins } from '@capacitor/core';
import { captureException } from '@sentry/capacitor';
import * as SentryCapacitor from '@sentry/capacitor';
// eslint-disable-next-line no-restricted-imports
import * as SentryReact from '@sentry/react';
import { getSentryParams } from '@travelwin/core';
import { appEnv } from '@travelwin/core/src/utils/env';

import { HttpError, isHttpError } from '../services/helpers/authorizedRequests';
import { CapturedNativeException } from './capturedNativeException';
import { SentryEventInfo } from './SentryEventReport/SentryEventReport';

/*
 * Sentry Capacitor (https://www.npmjs.com/package/@sentry/capacitor)
 *
 * Documented details and findings about our integration here:
 * https://simlocal.atlassian.net/wiki/spaces/UWLDP/pages/2523987990/Sentry+logging
 *
 */

// Used to expose methods to the native code
declare const window: {
  sentry?: any;
  logger?: any;
} & Window;

const getUserCallback = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return {
    id: user.attributes.sub,
    email: user.attributes.email,
  };
};

const allowEvent = (event: SentryReact.ErrorEvent) => {
  // Connection errors shouldn't be logged
  if (!navigator.onLine) {
    return false;
  }

  const errors = event.exception?.values;

  // API Compatibility errors shouldn't be logged
  const isApiCompatibilityError = errors?.find(
    (e: SentryCapacitor.Exception) => e.type === 'ApiCompatibilityError',
  );

  return !isApiCompatibilityError;
};

const setupSentry = () => {
  try {
    const sentryParams = getSentryParams({
      dsn: 'https://586146b24e0942aebb062b0b7adaf0a9@o4504831327666176.ingest.sentry.io/4504871062405120',
      getUserCallback,
      allowEvent,
      env: appEnv.getEnv(),
    });
    SentryCapacitor.init(
      sentryParams,
      SentryReact.init,
      // Adding SentryReact as sibling is Sentry capacitor's recommended approach.
      // Also, without this, calling captureException from @sentry/react doesn't work.
    );
  } catch (e) {
    console.error('Sentry is misconfigured');
  }
};

export const initSentry = () => {
  if (Capacitor.isNativePlatform()) {
    // This exposes Sentry.captureException, CapturedNativeException class and console methods to the native code.
    window.sentry = { captureException, CapturedNativeException };
    window.logger = console;

    // For mobile, Sentry should only capture events when we're in a build release.
    Plugins.AppInfoPlugin.getBuildInfo().then(
      ({ isDebugBuild }: { isDebugBuild: boolean }) => {
        if (!isDebugBuild) {
          setupSentry();
        }
      },
    );
  } else {
    // For web, Sentry should only capture events when we're in a deployed environment, not local.
    if (!appEnv.isLocal()) {
      setupSentry();
    }
  }
};

export const captureError = (
  error: unknown,
  level?: SentryCapacitor.SeverityLevel,
): SentryEventInfo | undefined => {
  if (!error) {
    return;
  }

  if (isHttpError(error)) {
    const httpError = error as HttpError;
    // Shorten the query for showing and grouping in Sentry
    const queryName = error.path
      .split(/[?#]/)[0] // Remove query params
      .replace(/^.*\/\/[^/]+/, '') // Remove origin
      .replace('ecommerce/', '')
      .replaceAll(/\/[^-/]+-[^-/]+-[^-/]+-[^-/]+-[^-/]+/g, '/<id>'); // Remove id params

    const message = `${error.method} ${queryName} [${error.status}]`;

    const eventId = SentryCapacitor.captureMessage(message, {
      level: level || 'fatal',
      extra: { error: JSON.stringify(error) },
      tags: {
        clientTransactionId: httpError.clientTransactionId,
        errorCode: httpError.body?.error_code,
      },
    });
    return {
      eventId,
      errorCode: httpError.body?.error_code,
    };
  } else if (typeof error === 'string') {
    const eventId = SentryCapacitor.captureMessage(error, {
      level: level || 'fatal',
    });
    return { eventId };
  } else {
    const eventId = SentryCapacitor.captureException(error);
    return { eventId };
  }
};

export const SentryErrorBoundary = SentryReact.ErrorBoundary;
