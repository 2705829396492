import { Capacitor, Plugins } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { useConfig } from '@travelwin/core';
import idx from 'idx';
import { createContext, ReactNode, useEffect, useRef, useState } from 'react';

import { captureError } from '../../monitoring/sentry';
import { mockSimSubscriptions } from './SimSubscriptionsMock';

export const EuiccManagerContext = createContext<any>(undefined);

interface Props {
  children: ReactNode;
}

const EuiccManager = ({ children }: Props) => {
  const { EUICC_INTERVAL_TIMEOUT_MS, EUICC_MAX_TIMEOUT_MS, SIMULATE_LPA } =
    useConfig();
  const [readPhoneStatePermissionGranted, setReadPhoneStatePermissionGranted] =
    useState<any>(null);
  const [permissionError, setPermissionError] = useState<any>(null);
  const [activeSubscriptionCount, setActiveSubscriptionCount] =
    useState<any>(null);
  const [activeSubscriptionCountMax, setActiveSubscriptionCountMax] =
    useState(null);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null);
  const [simInfo, setSimInfo] = useState<any>(null);
  const [simSubscriptions, setSimSubscriptions] = useState<any>(null);
  const simSubscriptionsRef = useRef(simSubscriptions);
  const setSimSubscriptionsRef = (subscriptions: any) => {
    simSubscriptionsRef.current = subscriptions;
    setSimSubscriptions(subscriptions);
  };
  const [euiccManagerStatus, setEuiccManagerStatus] = useState<any>(null);
  const [isEuiccManagerEnabled, setEuiccManagerEnabled] = useState(false);
  const [euiccInfoOsVersion, setEuiccInfoOsVersion] = useState('N/a');
  const [lpaStatus, setLpaStatus] = useState<any>(null);
  const [isLpaProcessing, setLpaProcessing] = useState(false);
  const [euiccActionResultType, setEuiccActionResultType] = useState<any>(null);
  const [euiccActionResultIsSuccess, setEuiccActionResultIsSuccess] =
    useState(false);
  const euiccActionResultIsSuccessRef = useRef(euiccActionResultIsSuccess);
  const setEuiccActionResultIsSuccessRef = (isSuccess: any) => {
    euiccActionResultIsSuccessRef.current = isSuccess;
    setEuiccActionResultIsSuccess(isSuccess);
  };
  const [euiccActionResultIsError, setEuiccActionResultIsError] =
    useState(false);
  const euiccActionResultIsErrorRef = useRef(euiccActionResultIsError);
  const setEuiccActionResultIsErrorRef = (isError: any) => {
    euiccActionResultIsErrorRef.current = isError;
    setEuiccActionResultIsError(isError);
  };
  const [resultCode, setResultCode] = useState<any>(null);
  const [resultCodeConstant, setResultCodeConstant] = useState<any>(false);
  const [detailedCode, setDetailedCode] = useState<any>(null);
  const [interactionCount, setInteractionCount] = useState(0);
  const isLpaPluginAvailable = Capacitor.isPluginAvailable('LpaNativePlugin');
  const [
    maxTimeLimitEuiccActionTimeoutState,
    setMaxTimeLimitEuiccActionTimeout,
  ] = useState<any>(null);
  const maxTimeLimitEuiccActionTimeoutRef = useRef(
    maxTimeLimitEuiccActionTimeoutState,
  );
  const setMaxTimeLimitEuiccActionTimeoutRef = (timeout: any) => {
    maxTimeLimitEuiccActionTimeoutRef.current = timeout;
    setMaxTimeLimitEuiccActionTimeout(timeout);
  };
  const [
    intervalCheckSubscriptionsTimeoutState,
    setIntervalCheckSubscriptionsTimeout,
  ] = useState<any>(null);
  const intervalCheckSubscriptionsTimeoutRef = useRef(
    intervalCheckSubscriptionsTimeoutState,
  );
  const setIntervalCheckSubscriptionsTimeoutRef = (interval: any) => {
    intervalCheckSubscriptionsTimeoutRef.current = interval;
    setIntervalCheckSubscriptionsTimeout(interval);
  };
  const [iccid, setIccid] = useState<any>(null);
  const iccidRef = useRef<any>(iccid);
  const setIccidsRef = (thisIccid: any) => {
    iccidRef.current = thisIccid;
    setIccid(thisIccid);
  };

  const successFadeOut = 2000;

  useEffect(() => {
    async function initPermissions() {
      try {
        const { LpaNativePlugin } = Plugins;
        const info = await Device.getInfo();
        setDeviceInfo(info);

        if (isLpaPluginAvailable) {
          setLpaProcessing(true);

          // Setup permissions listener
          const lpaNativePermissionPluginEventListener =
            LpaNativePlugin.addListener(
              'lpaPermissionPluginEvent',
              (msg: any) => {
                console.error(
                  'lpaPermissionPluginEvent Event Received %o',
                  msg,
                );
                const readPhoneStatePermissionAllowed =
                  idx(msg, (_) => _.readPhoneStatePermissionGranted) || null;
                if (readPhoneStatePermissionAllowed !== null) {
                  setReadPhoneStatePermissionGranted(
                    readPhoneStatePermissionAllowed,
                  );
                }
                setPermissionError(
                  idx(permissionInfo, (_) => _.permissionError),
                );
              },
            );

          console.error('Requesting permission: %o', LpaNativePlugin);
          const permissionInfo = await LpaNativePlugin.requestPermission({
            inputValue: 'Phone',
          });
          console.error('permissionInfo: %o', permissionInfo);
          const readPhoneStatePermissionAllowed =
            idx(permissionInfo, (_) => _.readPhoneStatePermissionGranted) ||
            null;
          if (readPhoneStatePermissionAllowed !== null) {
            setReadPhoneStatePermissionGranted(readPhoneStatePermissionAllowed);
          }

          return function () {
            lpaNativePermissionPluginEventListener.remove();
          };
        }
      } catch (e) {
        console.error('Error initialising LPA permissions');
        captureError(e);
      }
    }
    initPermissions();
  }, []);

  useEffect(() => {
    async function initLPA() {
      try {
        const { LpaNativePlugin } = Plugins;
        const info = await Device.getInfo();
        setDeviceInfo(info);

        if (isLpaPluginAvailable) {
          setLpaProcessing(true);

          // Setup listener
          const lpaNativePluginEventListener = LpaNativePlugin.addListener(
            'lpaNativePluginEvent',
            (msg: any) => {
              console.error('lpaNativePluginEvent Event Received %o', msg);
              const actionResultType = idx(msg, (_) => _.actionResultType);
              const resultCode = idx(msg, (_) => _.resultCode);
              const resultCodeConstant = idx(msg, (_) => _.resultCodeConstant);
              const isError =
                resultCodeConstant !== 'EMBEDDED_SUBSCRIPTION_RESULT_OK' &&
                resultCodeConstant !==
                  'EMBEDDED_SUBSCRIPTION_RESULT_RESOLVABLE_ERROR';
              const detailedCode = idx(msg, (_) => _.detailedCode);
              const interactionCount = idx(msg, (_) => _.interactionCount);
              console.info(
                'lpaNativePluginEvent detailedCode %o',
                detailedCode,
              );
              const permissionError = idx(msg, (_) => _.permissionError);
              if (permissionError) {
                setPermissionError(permissionError);
              }

              if (resultCode) {
                // If Download succeeded but the eSIM did not switch on still
                // consider it a success
                if (
                  actionResultType === 'downloadESIM' &&
                  iccidRef.current &&
                  idx(simSubscriptionsRef, (_) => _.current[iccidRef.current])
                ) {
                  setEuiccActionResultIsErrorRef(false);
                  setEuiccActionResultIsSuccessRef(true);
                  setTimeout(() => {
                    setLpaProcessing(false);
                  }, successFadeOut);
                  clearTimers();
                  return;
                }

                setEuiccActionResultType(actionResultType);
                setEuiccActionResultIsErrorRef(isError);
                setResultCode(resultCode);
                setResultCodeConstant(resultCodeConstant);
                setDetailedCode(detailedCode);
                setInteractionCount(interactionCount);
                console.info('Setting detailedCode %o', detailedCode);
                setLpaStatus({
                  resultCode,
                  resultCodeConstant,
                  detailedCode,
                  interactionCount,
                });
                if (
                  isError ||
                  resultCodeConstant === 'EMBEDDED_SUBSCRIPTION_RESULT_OK'
                ) {
                  console.error(
                    `Operation ended with ${isError ? 'failure' : 'success'}`,
                  );
                  clearTimers();
                  setLpaProcessing(false);
                }
              }
            },
          );
          const lpa = await LpaNativePlugin.initLpa({
            inputValue: 'LPA',
          });
          setEuiccManagerEnabled(SIMULATE_LPA ? true : lpa.lpaEnabled);
          setEuiccManagerStatus(lpa.lpaManagerStatus);
          setEuiccInfoOsVersion(lpa['euiccInfo.osVersion']);

          return function () {
            lpaNativePluginEventListener.remove();
          };
        }
        setEuiccManagerStatus('No Android LPA found.');
        setEuiccManagerEnabled(SIMULATE_LPA);
      } catch (e) {
        setEuiccManagerStatus('No Android LPA found.');
        captureError(e);
      } finally {
        setLpaProcessing(false);
      }
    }
    initLPA();
  }, []);

  const refreshSimSubscriptions = async () => {
    let simInfo = null;
    if (isLpaPluginAvailable) {
      const { LpaNativePlugin } = Plugins;
      simInfo = await LpaNativePlugin.getSimInfo({
        type: 'esim',
      });
    }
    if (SIMULATE_LPA) {
      simInfo = mockSimSubscriptions;
    }
    setSimInfo(simInfo);
    setActiveSubscriptionCount(
      idx(simInfo, (_) => _.activeSubscriptionInfoCount),
    );
    setActiveSubscriptionCountMax(
      idx(simInfo, (_) => _.activeSubscriptionInfoCountMax),
    );
    const readPhoneStatePermissionAllowed =
      idx(simInfo, (_) => _.readPhoneStatePermissionGranted) || null;
    if (readPhoneStatePermissionAllowed !== null) {
      setReadPhoneStatePermissionGranted(readPhoneStatePermissionAllowed);
    }
    const permissionError = idx(simInfo, (_) => _.permissionError);
    if (permissionError) {
      setPermissionError(permissionError);
    }
    const subscriptions: Record<string, any> = {};
    if ((idx(simInfo, (_) => _.subscriptions.length) || 0) > 0) {
      simInfo.subscriptions.forEach((subscription: { iccid: string }) => {
        subscriptions[subscription.iccid] = subscription;
      });
    }
    setSimSubscriptionsRef(subscriptions);
    return subscriptions;
  };

  const downloadESIM = async (
    iccid: string,
    activationCode: string,
    smdpPlusAddress?: string,
  ) => {
    const { LpaNativePlugin } = Plugins;
    try {
      clearTimers();
      // Wait for the result
      // The LPA doesn't return a success hence we just check what happens
      // with the SIM subscriptions
      const intervalCheckSubscriptionsTimeout = setInterval(() => {
        if (
          idx(simSubscriptionsRef, (_) => _.current[iccid].isActive) === true
        ) {
          setEuiccActionResultIsErrorRef(false);
          setEuiccActionResultIsSuccessRef(true);
          setLpaProcessing(false);
          clearTimers();
        }
        refreshSimSubscriptions(); // todo: this is an async that should be waited upon
      }, EUICC_INTERVAL_TIMEOUT_MS);
      prepareEuiccAction(
        'downloadESIM',
        intervalCheckSubscriptionsTimeout,
        false,
        iccid,
      );

      if (LpaNativePlugin) {
        await LpaNativePlugin.downloadESIM({
          activationCode,
          smdpPlusAddress,
        });
        const deviceInfo = await Device.getInfo();

        if (deviceInfo.platform === 'ios') {
          setEuiccActionResultIsErrorRef(false);
          setEuiccActionResultIsSuccessRef(true);
          setLpaProcessing(false);
          clearTimers();
        }
      } else {
        setEuiccActionResultIsErrorRef(true);
        captureError('Null LpaNativePlugin');
      }
    } catch (e) {
      setEuiccActionResultIsErrorRef(true);
      setLpaProcessing(false);
      captureError(e);
    }
  };

  const prepareEuiccAction = (
    actionResultType: string,
    intervalCheckSubscriptionsTimeout: any,
    suppressProcessingFlag = false,
    iccid: string,
  ) => {
    if (suppressProcessingFlag !== true) {
      setLpaProcessing(true);
    }
    setEuiccActionResultType(actionResultType);
    setEuiccActionResultIsSuccessRef(false);
    setEuiccActionResultIsErrorRef(false);
    setResultCode(null);
    setResultCodeConstant(null);
    setDetailedCode(null);
    setInteractionCount(0);
    setIccidsRef(iccid);

    const maxTimeLimitEuiccActionTimeout = setTimeout(() => {
      clearTimers();
      console.error(`${actionResultType} max time-out reached.`);

      // If Download succeeded but the eSIM did not switch on still consider
      // it a success
      if (
        actionResultType === 'downloadESIM' &&
        iccid &&
        idx(simSubscriptionsRef, (_) => _.current[iccid])
      ) {
        setEuiccActionResultIsErrorRef(false);
        setEuiccActionResultIsSuccessRef(true);
        setTimeout(() => {
          setLpaProcessing(false);
        }, successFadeOut);
        return;
      }
      setEuiccActionResultIsErrorRef(true);
      setLpaProcessing(false);
      captureError('eSIM direct download timeout');
    }, EUICC_MAX_TIMEOUT_MS);
    setIntervalCheckSubscriptionsTimeoutRef(intervalCheckSubscriptionsTimeout);
    setMaxTimeLimitEuiccActionTimeoutRef(maxTimeLimitEuiccActionTimeout);
  };

  const clearTimers = () => {
    clearInterval(intervalCheckSubscriptionsTimeoutRef.current);
    clearTimeout(maxTimeLimitEuiccActionTimeoutRef.current);
  };

  const acknowledgeEuiccError = () => {
    clearTimers();
    setEuiccActionResultType(null);
    setEuiccActionResultIsErrorRef(false);
    setLpaProcessing(false);
  };

  const value = {
    readPhoneStatePermissionGranted,
    permissionError,
    deviceInfo,
    simInfo,
    simSubscriptions,
    activeSubscriptionCount,
    activeSubscriptionCountMax,
    euiccManagerStatus,
    isEuiccManagerEnabled,
    euiccInfoOsVersion,
    downloadESIM,
    refreshSimSubscriptions,
    lpaStatus,
    isLpaProcessing,
    euiccActionResultType,
    euiccActionResultIsError,
    euiccActionResultIsSuccess,
    acknowledgeEuiccError,
    resultCode,
    resultCodeConstant,
    detailedCode,
    interactionCount,
  };

  return (
    <EuiccManagerContext.Provider value={value}>
      {children}
    </EuiccManagerContext.Provider>
  );
};

export default EuiccManager;
