import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const AffiliateKeyName = 'affiliate_partner';

/**
 * This file has helper functions that allows B2B partners to use our mobile app as "theirs".
 * Partners will push app downloads for us, and we'll retribute for each plan sold.
 * Cellcom is an example of a partner that asked this kind of feature.
 */
export const storeAffiliate = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  const affiliate = getAffiliateFromUrl();
  if (affiliate) {
    await Preferences.set({ key: AffiliateKeyName, value: affiliate });
  }
};

export const getAffiliateFromStorage = async () => {
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  const { value } = await Preferences.get({ key: AffiliateKeyName });
  return value;
};

const getAffiliateFromUrl = () => {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get(AffiliateKeyName);
};
