import { BrandComponentsMap } from '../../theme-types';

export const FaqQuestion3Icon: BrandComponentsMap['FaqQuestion3Icon'] = ({
  size,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_94_5189)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.174 8.80832C39.8275 4.52503 35.023 1.59396 29.7047 0.491035C26.0787 -0.264395 22.3468 -0.143526 18.8039 0.800761C18.8039 0.800761 18.7963 0.800761 18.7888 0.800761C18.7057 0.823424 18.6226 0.846087 18.547 0.86875C15.4044 1.69972 12.4054 3.20303 9.88225 5.11426C5.34212 8.53636 2.21464 13.5298 1.02107 19.0822C-0.172513 24.6346 0.635796 30.6025 3.40822 35.5732C6.0069 40.2266 10.2524 43.9358 15.1325 46.0359C20.1032 48.1813 25.6405 48.6119 30.853 47.0859C35.9446 45.5902 40.5074 42.4325 43.7331 38.2247C46.9965 33.9717 48.6962 28.8196 48.5074 23.4485C48.3185 18.153 46.4375 12.9783 43.1816 8.80832H43.174ZM43.9597 33.7753C39.5404 42.508 29.1759 47.6827 19.582 45.4089C9.49699 43.0142 2.14666 33.9112 2.35818 23.4939C2.47904 17.4127 4.87376 11.6638 9.37612 7.53164C10.819 6.20964 12.3903 5.12182 14.0522 4.26063C17.5348 2.63646 21.4328 2.11521 25.3232 2.2814C30.2486 2.49292 35.1514 4.27574 38.8303 7.60718C45.9389 14.0359 48.3412 25.1483 43.9672 33.7753H43.9597Z"
          fill="#2F2D30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9987 22.7309C17.9959 21.0765 19.061 19.4145 19.892 17.6695C20.4737 16.4608 20.791 14.8215 19.5596 13.8848C18.5171 13.0916 17.0138 13.4844 16.1526 14.3607C16.0922 14.4212 16.0393 14.4891 15.9864 14.5496C15.9109 14.3078 15.8127 14.0812 15.6691 13.8697C14.9364 12.8196 13.5086 12.389 12.5114 13.356C11.3783 14.4665 11.8467 16.0529 12.3679 17.322C12.7305 18.2059 13.0931 19.0822 13.4708 19.9585C13.8486 20.8499 14.1205 21.9301 14.808 22.6327C15.027 22.8517 15.3443 22.8442 15.5709 22.6856C15.8656 23.1464 16.6361 23.3277 17.0063 22.7158L16.9987 22.7309ZM18.7966 15.5619C18.8344 15.7583 18.8042 15.9849 18.7589 16.1889C18.774 15.9773 18.7815 15.7658 18.7966 15.5619ZM13.3802 15.6525C13.4935 16.0227 13.5993 16.3853 13.7126 16.7554C13.5615 16.3853 13.4331 16.0076 13.3802 15.6525Z"
          fill="#2F2D30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5298 14.8442C32.2502 15.0784 32.0312 15.3503 31.865 15.6449C31.5855 14.9877 31.3211 14.3078 30.8149 13.779C29.8706 12.797 28.2994 12.8574 27.7328 14.2021C27.1058 15.6827 28.3598 17.4882 28.9339 18.7951C29.3418 19.7092 29.7347 20.6232 30.1577 21.5297C30.5656 22.406 30.8829 23.4108 31.6157 24.068C31.8574 24.2871 32.22 24.2568 32.424 24.0604C32.8546 24.272 33.4287 24.2191 33.6856 23.7205C34.5468 22.0132 35.3022 20.2153 36.0274 18.4476C36.5033 17.2842 36.8433 15.7658 35.831 14.7686C34.9169 13.8697 33.4514 14.0737 32.5298 14.8442ZM34.1766 16.2342C33.95 16.1586 33.7007 16.2115 33.5723 16.5061C33.4514 16.7705 33.3381 17.0349 33.2248 17.3069C33.2701 16.9216 33.3909 16.5666 33.6327 16.3097C33.814 16.1133 34.2899 15.8338 34.5619 16.0227C34.6676 16.0982 34.7205 16.2115 34.7432 16.3399C34.5845 16.2342 34.3806 16.1889 34.1841 16.2417L34.1766 16.2342Z"
          fill="#2F2D30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.671 32.1964C32.9458 32.5666 32.5303 33.3673 31.9562 33.9339C31.367 34.5156 30.7022 35.0066 29.977 35.407C28.4359 36.2682 26.6909 36.7214 24.9232 36.6232C21.4709 36.4268 18.4114 33.949 16.9912 30.882C16.757 30.3758 15.9033 30.7309 16.0846 31.2672C17.3538 35.0746 20.791 37.7715 24.7721 38.2323C26.7815 38.4665 28.8514 38.0736 30.6569 37.1747C32.1828 36.4117 34.7135 34.7573 34.8193 32.8687C34.8571 32.2644 34.1545 31.9622 33.6786 32.2115L33.671 32.1964Z"
          fill="#2F2D30"
        />
      </g>
      <defs>
        <clipPath id="clip0_94_5189">
          <rect
            width="48.0302"
            height="48"
            fill="white"
            transform="translate(0.484375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
