import './CompleteIcon.scss';

const CompleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <g transform="matrix(1.092354 0 0 1.092354-37.388326-37.388326)">
      <path
        id="CompleteIconInner1"
        d="M86,52L56.6665,80L42,66"
        transform="matrix(.692884 0 0 0.692884 35.655424 36.269656)"
        fill="none"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDashoffset="60.83"
        strokeDasharray="60.827625"
        className="c-PrimaryColorStroke"
      />
      <g
        id="CompleteIconInner2"
        transform="translate(93.670481,93.670462) scale(1,1)"
      >
        <path
          d="M50.329519,84.659077c18.959691,0,34.329518-15.369836,34.329518-34.329539s-15.369827-34.329538-34.329518-34.329538C31.369827,16,16,31.369835,16,50.329538s15.369827,34.329539,34.329519,34.329539Z"
          transform="translate(-64,-64)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="c-PrimaryColorStroke"
        />
      </g>
    </g>
  </svg>
);

export default CompleteIcon;
